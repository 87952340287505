import { Config } from "common/config"
import { useIntersection } from "common/hooks"
import { Styled } from "components/Styled"
import { useRef } from "react"
import { Fade } from "react-awesome-reveal"
import { Component } from "./components"


export const Stops = () => {
    const refAnimation = useRef(null)
    const isInteract = useIntersection({ element: refAnimation })

    return(
        <Styled.Section id={Config.Section.stops}>
            <Styled.Container ref={refAnimation} style={{ opacity: isInteract ? 1 : 0 }}>
                <Fade triggerOnce direction="up"  delay={300} >
                    <Styled.H2>Наші зупинки</Styled.H2>
                </Fade>
                <Component.StopsList/>
            </Styled.Container>
        </Styled.Section>
    )
}