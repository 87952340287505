import { EColors } from "common/styles";
import { Styled } from "components/Styled";

import styled from "styled-components";

export const StyledFooter = styled.footer`
width:100%;
background: ${EColors.neutral_800};
`
export const StyledFooterContainer = styled(Styled.Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 34px 16px;
  
  @media screen and (max-width: 999px) {
    gap: 48px;
  }
  @media screen and (min-width: 1000px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 48px 24px;
  }
  @media screen and (min-width: 1440px) {
    padding: 48px 72px;
  }
`;
export const StyledFooterMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media screen and (max-width: 999px) {
    align-items: center;
  }
`;
