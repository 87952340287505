import { Svg } from "common/assets";
import { useResolution } from "common/hooks";
import { useEffect } from "react";
import { ButtonClose, IconClose, ModalWrapper, StyledModal } from "./styled";
import { TModalProps } from "./types";

const isChrome = navigator.appVersion.indexOf("Chrome/") != -1;

export const Modal = ({
  modalActive,
  setModalActive,
  top,
  children,
  maxWidth,
  bgColor,
  height,
  ...props
}: TModalProps) => {
  const {isMinScreen768}=useResolution()
  
  const handleClick = () => {
    setModalActive(!modalActive)
  }

  useEffect(() => {
    modalActive && isChrome && isMinScreen768 ? document.body.style.paddingRight = "17px" : document.body.style.paddingRight = "0"
    modalActive
      ? (document.body.style.overflowY = "hidden")
      : (document.body.style.overflowY = "inherit");

    if (modalActive) {
      const scrollY = document.body.style.top;
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }
  }, [modalActive])

  return (
    <StyledModal active={modalActive ? "active" : ""}>
      <ModalWrapper
        active={modalActive ? "active" : ""}
        maxWidth={maxWidth}
        bgColor={bgColor}
        height={height}
        top={top}
      >
        <ButtonClose onClick={handleClick}>
          <IconClose>
            <use href={Svg.sprite + "#icon-close"} />
          </IconClose>
        </ButtonClose>
        {children}
      </ModalWrapper>
    </StyledModal>
  );
};
