import { EColors } from "common/styles";
import styled from "styled-components";

export const LabelSwitch = styled.label`
cursor: pointer;
text-indent: -9999px;
width: 48px;
height: 28px;
background: ${EColors.neutral_300};
display: block;
border-radius: 100px;
position: relative;
&:after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 24px;
  height: 24px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}
`
export const InputCheckbox = styled.input`
height: 0;
width: 0;
visibility: hidden;
&:checked + ${LabelSwitch}:after {
 left: -2px;
 transform: translateX(100%);
};
&:checked + ${LabelSwitch} {
    background: ${EColors.primary_400};   
};
`
