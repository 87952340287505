import { isDisabled } from '@testing-library/user-event/dist/utils';
import { Svg } from 'common/assets';
import { useResolution } from 'common/hooks';
import { EColors } from 'common/styles';
import ReactSelect, { type DropdownIndicatorProps, type PlaceholderProps, components } from 'react-select'
import { Error, Label, StyledSelectWrapper, StylePlaceholder } from "./styled";
import { SelectProps } from "./types";


const DEFAULT_WIDTH = '100%'
const DEFAULT_HEIGHT = '52px'

const Placeholder: React.FC<PlaceholderProps> = props => {
    return <components.Placeholder {...props} />;
};

const CaretDownIcon = () => {
    return <img src={ Svg.ArrowDown} />;
};
const DropdownIndicator: React.FC<DropdownIndicatorProps> = props => {
    return (
        <components.DropdownIndicator {...props}>
            <CaretDownIcon />
        </components.DropdownIndicator>
    );
};
export const SelectInput= ({
    name,
    placeholder,
    label,
    options,
    isInvalid,
    errorText,
    disabled,
    ...restProps
    
}: SelectProps) => {
    const {isMinScreen768}=useResolution()
    const selectedOption = options.find(item => item.value === restProps.value);
    const height = isMinScreen768 ? "52px" : "44px"
    return (
        <StyledSelectWrapper>
            <Label disabled={disabled}>
                {label}
            </Label>
            <ReactSelect
                name={name}
                placeholder={<StylePlaceholder>{placeholder}</StylePlaceholder>}
                components={{ Placeholder, DropdownIndicator }}
                options={options}
                value={selectedOption}
                {...restProps}
                styles={{
                    placeholder: base => ({
                        ...base,
                        fontFamily:"'Roboto', sans-serif",
                        fontWeight: 400,
                        fontSize: "16px",
                        color: `${EColors.neutral_600}`,
                    }),
                    /* eslint-disable */
                    control: (style, { isDisabled,isFocused}) => ({
                        ...style,
                        height: height,
                        border: isDisabled ? `1px solid ${EColors.neutral_300}` : `1px solid ${EColors.neutral_300}`,
                        backgroundColor: isDisabled ? `${EColors.neutral_200}` : `${EColors.white}`,
                        boxShadow: 'none',
                        borderRadius: '8px',
                        "&:hover": {
                            borderColor: `${EColors.neutral_300}`
                        },
                        "&:active": {
                            borderColor: `${EColors.neutral_800}`
                        },
                        "&:disable": {
                            borderColor: `${EColors.neutral_300}`
                        },
                       
                     
                    }),
                    /* eslint-disable */
                    option: (styles, {isDisabled,  isSelected }) =>({
                        ...styles,
                        color: `${EColors.neutral_800}`,
                        backgroundColor: isSelected ? `${EColors.neutral_300}` : `${EColors.neutral_200}`,
                        borderColor: `${EColors.neutral_300}`,
                        cursor: isDisabled ? 'not-allowed' : 'default',    
                    }),
                    indicatorSeparator: (baseStyles, state) => ({
                        color:'transparent'
                    }),
                   
                    indicatorsContainer: (baseStyles, isFocusable) => ({
                        ...baseStyles,
                        color: `${EColors.neutral_600}`,
                     
                    })
                 
                }}
            
            />
            {isInvalid && errorText && <Error>{errorText}</Error>}
        </StyledSelectWrapper>
    )
}