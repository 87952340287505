import { EColors } from "common/styles";
import { Styled } from "components/Styled";
import styled from "styled-components";


export const StyledRouteItem = styled.li`
  width: 100%;
  @media screen and (min-width: 768px) {
    width: 50%;
  }
`

export const StyledImage = styled.img`
  width: 100%;
  height:100%;
  transition: transform 0.3s linear;
`; 


export const ButtonHover = styled.div`
  opacity: 0;
  transition: all 0.4s linear;
  @media screen and (max-width: 1199px) {
    position: absolute;
    top: 2px;
    left: 12px;
  }
`;

export const StyledRoute = styled(Styled.Subtitle_2)`
  color: ${EColors.white};
  opacity: 1;
  transition: all 0.4s linear;
`;
export const StyledRoutesList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`
export const StyledDescribeRoute = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  bottom: 0;
  background: rgba(16, 16, 16, 0.44);
  backdrop-filter: blur(2px);
  padding: 12px 16px;
  transition: all 0.4s linear;

 
  @media screen and (min-width: 768px) {
    padding: 12px 34px;
  };
  @media screen and (max-width: 1199px) {
    &:hover ${StyledRoute} {
      opacity: 0;
    }
  };
  `
export const StyledRouteWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  &:hover  ${StyledImage}{
  transform: scale(1.05);
  }
  &:hover ${StyledDescribeRoute} ${ButtonHover} {
    opacity: 1;
  }; 
`;


export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 36px 28px;
  @media screen and (max-width: 767px) {
     padding: 16px 16px 28px;
  }
`;
export const StyledText = styled(Styled.PBody1)`
  text-align: center;
  margin-top: 16px;
`;
export const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 28px;
  margin-bottom: 28px;
  @media screen and (max-width: 767px) {
    margin-top: 16px;
  }
`;

export const Iframe = styled.iframe`
width: 100%;
height: 338px; 
border-radius: 8px; 
border:none;
`