import { useEffect, useState } from 'react'
import { Error,  Label,  StyledInput, StyledInputBox, StyledInputWrapper } from './styled'
import { StadartProps } from './types'

const DEFAULT_WIDTH = '100%'
const DEFAULT_HEIGHT = '52px'

export const Input = ({
  width = DEFAULT_WIDTH,
  height = DEFAULT_HEIGHT,
  type = 'text',
  name,
  label,
  placeholder,
  isInvalid,
  errorText,
  bgImage='',
  ...props
}: StadartProps) => {
  const [showType, setShowType] = useState(false)

  useEffect(() => {
    !!props.value && setShowType(true)
  }, [props.value])

  const isTypeText = type === 'text'
  const currentType = showType ? type : isTypeText ? 'text' : 'number'

  const isDate = type === 'date'
  const isTime = type === 'time'

  const dateTimePlaceholder = showType ? '' : placeholder
  const currentPlaceholder =
    isDate || isTime ? dateTimePlaceholder : placeholder

  return (
    <>
      <StyledInputWrapper>
        {label && (
          <Label htmlFor={name}>
            {label}
          </Label>
        )}
        <StyledInputBox bgImage={bgImage} type={currentType}>
        <StyledInput
          id={name}
          {...props}
          onFocus={() => setShowType(true) }
          onTouchStart={() => setShowType(true)}
          name={name}
          type={currentType}
          placeholder={currentPlaceholder}
          width={width}
          height={height}
          invalid={isInvalid?'false':'true'}
          autoComplete="on"
        />

          {isInvalid && errorText && <Error>{errorText}</Error>}
        </StyledInputBox>
      </StyledInputWrapper>
    </>
  )
}
