import { EColors } from "common/styles"
import { Styled } from "components/Styled"
import { Svg } from 'common/assets';
import { ImageIcon } from "../Error/styled";

export const Impossible = () => {
    const title = "Бронювання недоступне";
    return (
        <>
            <ImageIcon src={Svg.Error} alt='icon' />
            <Styled.H3 align='center'>{title}</Styled.H3>
            <Styled.PBody1 color={EColors.neutral_600} align='center'>
                Бронювання  квитків тимчасово припинено!
                {/* В зв&apos; язку зі святковими датами, рейс скасовано! */}
            </Styled.PBody1>
        </>
    )
}