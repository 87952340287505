import { EColors } from "common/styles";
import { FONT } from "common/utils";
import styled, { css } from "styled-components";
import { StyledInputProps } from "./types";
import { Field } from "formik";
import { InputHTMLAttributes } from "react";
import { Svg } from "common/assets";

const isChrome = navigator.appVersion.indexOf("Chrome/") != -1;
const isFF = navigator.appVersion.indexOf("Firefox") != -1;

export const Label = styled.label<{
  disabled?:boolean
}>`
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 20.16px;
  color:  ${({ disabled }) => disabled ? `${ EColors.neutral_600 }`:` ${EColors.neutral_800}`};
`;

export const StyledInput = styled(Field)<StyledInputProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background: ${EColors.white};
  border: 1px solid ${EColors.neutral_300};
  border: ${({ invalid }) =>
    invalid === "false"
      ? "1px solid #E04E4E"
      : `1px solid ${EColors.neutral_300}`};
  border-radius: 8px;
  padding: 10px 12px;
  outline: none;
  display: flex;
  align-items: center;
  ${FONT({ color: EColors.neutral_800, weight: "400", size: "16px" })}
  transition:all 0.3s linear;
  &::placeholder {
    ${FONT({ color: "#A0A0A0", weight: "400", size: "16px" })};
  }
  -moz-appearance: textfield;
  -webkit-box-shadow: inset 0 0 0 50px #fff;
::-moz-placeholder, :-moz-placeholder {
    opacity: 1;
}

  &:placeholder-shown {
${FONT({ color: "#A0A0A0", weight: "400", size: "16px" })};
    border: ${({ invalid }) =>
      invalid === "false"
        ? "1px solid #E04E4E"
        : `1px solid ${EColors.neutral_300}`};
  }
  &:focus,
  &:active{
     border: ${({ invalid }) =>
       invalid === "false"
         ? "1px solid #E04E4E"
         : `1px solid ${EColors.neutral_300}`};
  }
  
  &:not(:placeholder-shown) {
    border: ${({ invalid }) =>
      invalid === "false"
        ? "1px solid #E04E4E"
        : `1px solid ${EColors.neutral_300}`};
  }
  
  ${({ name, invalid }) =>
    (name === "date" || name === "time" || name === "passengers") &&
    invalid === "false" &&
    css`
      border: 1px solid #e04e4e;
      :not(:placeholder-shown) {
        border: 1px solid #e04e4e;
      }
    `}

  ${({ name, invalid }) =>
    (name === "date" || name === "time" || name === "passengers") &&
    invalid === "true" &&
    css`
      border: 1px solid ${EColors.neutral_300};
      :not(:placeholder-shown) {
        border: 1px solid ${EColors.neutral_300};
      }
    `}

    /*Cancel brauser's styled*/

   ::-webkit-calendar-picker-indicator {
    filter: ${EColors.white};
    opacity: 0;
    background-color:transparent;
    background-image: url(${Svg.Calendar});
    width: 24px;
    height: 24px;
    cursor: pointer;
    border-radius: 50%;
    margin-left: 12px;
    position:relative;
    z-index:1;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
@media screen and (max-width: 767px) {
    height:44px;
    min-width: 120px;
}

@media screen and (min-width: 455px) {
   ${({ name}) =>name === "date"  &&
    css`
     min-width: 178px;
    `}
}
`;

export const StyledInputWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Error = styled.span`
  position: absolute;
  top: 105%;
  width: 100%;
  height: 5px;
  ${FONT({ size: "16px" })};
  color: #e04e4e;
`;

export const StyledSelectWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledInputBox = styled.div<{
  type: InputHTMLAttributes<HTMLInputElement>["type"];
  bgImage?: string;
}>`
  position: relative;
  width: 100%;

  &::after {
    position: absolute;
    content: "";
    background-color: ${EColors.white};
    background-image: ${({ bgImage }) => `url(${bgImage})`};
    fill: rgb(109, 109, 109);
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    cursor: pointer;
    pointer-events: initial;
    top: 25%;
    right: 12px;
    
  };
 input[type='date']:not(.has-value):before {
    color: lightgray;
    content: attr(placeholder);
  }

@media screen and (min-width: 767px) {
  ${({ type }) =>
    (!isChrome && (type === 'date' ))  &&
    css`
      ::after {
        position: absolute;
        content: '';
        filter: ${EColors.neutral_600};
        opacity: 1;
        background-image: url(${Svg.Calendar});
        width: 24px;
        height: 24px;
        cursor: pointer;
        right: 12px;
        top: 25%;
      }
    `}
}
`;

export const StylePlaceholder = styled.div`
  ${FONT({ color: `${EColors.neutral_600}`, weight: "400", size: "16px" })}
`;
