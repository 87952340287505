import { Config } from 'common/config'
import {  ItemsList, Link, LinkItem } from './styled'

export const Menu = () => {
  return (
    <>
        <ItemsList>
        {Config.links.map((link, index) => (
            <LinkItem key={index}>
            <Link href={link.href} >{link.title}</Link>
            </LinkItem >
          ))}
        </ItemsList>
    </>
  )
}
