import { EColors } from "common/styles"
import { Styled } from "components/Styled"
import { StyledItem, StyledListItem, StyledListPhone, StyledTitle } from "./styled"
import { TItem, TListProps } from "./types"

export const ListPhone = ({list, title}:TListProps) => {
    return (
        <StyledListPhone>
            <StyledItem>
            <StyledTitle>
                {title}
            </StyledTitle>
            
                {list.map((driver: TItem, index: number) => (
                    <StyledListItem key={index}>
                        <Styled.PBody1SB>{driver.phone}</Styled.PBody1SB>
                        <Styled.PBody2 color={EColors.neutral_600}>{driver.name}</Styled.PBody2>
                    </StyledListItem>
                ))}
            </StyledItem>
        </StyledListPhone>
    )
}