import { EColors, EFont } from "common/styles";
import styled from "styled-components";


export const StyledVisibleTitle = styled.button<{
  active: string;
}>`
  width: 100%;
  display: flex;
  align-items: flex-start;
  outline: none;
  text-decoration: none;
  border: none;
  background: transparent;
  font: inherit;
  color: inherit;
  user-select: none;
  cursor: pointer;
`;
export const StyledTitle = styled.p`
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 28px;
  line-height: 39.2px;
  text-align: left;
  @media screen and (max-width: 767px) {
    font-size: 20px;
    line-height: 24.4px;
  }
`;
export const StyledAccordionItem = styled.div`
  padding: 40px 0;
  border-bottom: 1px solid ${EColors.neutral_300};
  @media screen and (max-width: 767px) {
    padding: 28px 0 32px;
  }
`;

export const StyledInvisibleContent = styled.div`
  transition: height 0.2s ease-in-out;
  overflow: hidden;
`;
export const StyledSpanContext = styled.div`
  padding-top: 28px;
  font-family: ${EFont.Roboto};
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  font-feature-settings: "case" on;
  text-align: left;
  @media screen and (max-width: 767px) {
    padding-top: 16px;
  }
`;

export const IconImage = styled.img<{
  active: string;
}>`
  margin-left: auto;
  width: 32px;
  height: 32px;
  transition: transform 0.2s ease-in-out;
  animation: ${(props) =>
    props.active === "active"
      ? "rotatePlus 0.2s linear"
      : "rotateMinus 0.2s linear"};
  @keyframes rotateUp {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
  @keyframes rotateDown {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(-180deg);
      -moz-transform: rotate(-180deg);
      -webkit-transform: rotate(-180deg);
      -o-transform: rotate(-180deg);
      transform: rotate(-180deg);
    }
  }
`;
