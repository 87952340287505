import { Context } from 'common/contexts'
import { GlobalStyles } from 'common/styles'
import { AppRouter } from 'routes'


export const App = () => {
  return (
    <>
      <Context>
        <GlobalStyles />
        <AppRouter />
      </Context>
    </>
  )
}

export default App
