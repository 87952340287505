import { Svg } from 'common/assets'
import { MenuContext } from 'common/contexts/Menu'
import { Styled } from 'components/Styled'
import { useContext, useEffect, useState } from 'react'
import { Component } from '..'
import { MenuButton } from './styled'

export const RightMenu = () => {
  const { isOpen, setIsOpen } = useContext(MenuContext)
  const [isMenuButtonIsOpen, setIsMenuButtonIsOpen] = useState(isOpen)


  const MenuIcon = isMenuButtonIsOpen ? Svg.Cross : Svg.Menu

  const onMenuOpen = () => {
    setIsMenuButtonIsOpen(state => !state)
    setIsOpen(!isMenuButtonIsOpen)
  }

  useEffect(() => {
    setIsMenuButtonIsOpen(isOpen)
  }, [isOpen])

  return (
    <>
      <Styled.FlexWrapper gap={30}>
        {/* Menu button */}
        <MenuButton onClick={onMenuOpen}>
          <img src={MenuIcon} />
        </MenuButton>
      </Styled.FlexWrapper>
    </>
  )
}
