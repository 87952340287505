import { EColors } from "common/styles"
import styled from "styled-components"

export const StyledModal = styled.div<{
    active: string
}>`
width:100vw;
height:100vh;
background-color:rgba(0, 0, 0, 0.44);
position:fixed;
top:0;
left:0;
z-index:15;
overflow-y: scroll;
display: flex;
justify-content: center;
align-items: center;
opacity:${({ active }) => active === "active" ? "1" : "0"};
/* visibility: ${({ active }) => active === "active" ? "visible" : "none"}; */
pointer-events:${({ active }) => active === "active" ? "all" : "none"};
/* transform: ${({ active }) => active === "active" ? "scale(1)" : "scale(0)"}; */
/* transition: all 0.5s linear; */

`

export const IconClose = styled.svg`
width:40px;
height: 40px;
fill:#A0A0A0;
`

export const ModalWrapper = styled.div<{
    maxWidth?: string;
    bgColor?: string;
    height?:string
    active: string;
    top?: string;
}>`
position:absolute;
top:${({ top}) => top};
width:100%;
max-width:${({ maxWidth }) => maxWidth};
height:auto;
height:${({ height }) => height};
display: flex;
flex-direction: column;
padding-top:60px;
background:${({ bgColor }) => bgColor? `${bgColor}`: EColors.white};
visibility: ${({ active }) => active === "active" ? "visible" : "none"};
transform: ${({ active }) => active === "active" ? "scale(1)" : "scale(0)"};
border-radius: 16px;
box-shadow: 0px 12px 24px 0px rgba(166, 166, 166, 0.20); 
transition: all 0.5s linear 0.2s;
z-index: 16;
@media screen and (max-width: 767px) {
    top:102px;
}
`
export const ButtonClose = styled.button`
outline:none;
border:none;
background:transparent;
position:absolute;
top:20px;
right:36px;
cursor: pointer;
z-index:17;
@media screen and (max-width: 767px) {
    right:16px;
}
`
