import { Svg } from "common/assets";
import { Footer } from "views/ConditionPayment/Footer";
import { StyledConditionPage, StyledLogo } from "views/ConditionPayment/styled";
import { AgreementContent } from "./AgreementContent";

export const PrivacyPolicy = () => {
  return (
    <StyledConditionPage>
      <StyledLogo href="/">
        <img src={Svg.Logo} alt="logo" />
      </StyledLogo>
      <AgreementContent />
      <Footer />
    </StyledConditionPage>
  );
};
