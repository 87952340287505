import { EColors, EFont } from "common/styles";
import styled from "styled-components";

export const ItemsList = styled.ul`
  list-style-type: none;
  display: flex;
  gap: 28px;
`;

export const LinkItem = styled.li`
  position: relative;
  &:after {
    position: absolute;
    display: block;
    content: "";
    width: 0%;
    height: 1px;
    background: ${EColors.primary_500};
    position: absolute;
    bottom: -4px;
    left: 0;
    transition: all 0.2s ease;
  }
  &:hover:after {
    width: 100%;
    background: ${EColors.primary_500};
  }
`;
export const Link = styled.a`
  text-decoration: none;
  font-family: ${EFont.Roboto};
  font-weight: 400;
  font-size: 16px;
  line-height: 126%;
  font-feature-settings: "case" on;
  color: ${EColors.neutral_800};
  transition: all 0.3s linear;
  @media screen and (max-width: 768px) {
    font-family: ${EFont.Oswald};
    font-weight: 500;
    font-size: 28px;
    line-height: normal;
  }
  &:hover {
    color: ${EColors.primary_500};
  }
`;
