import { ContainerWrapper, } from "./styled";
import { Component } from "./components";
import { useIntersection } from "common/hooks";
import { useRef } from "react";
import { Fade } from "react-awesome-reveal";

export const About = () => {
 

  return (
    <ContainerWrapper  >
      <Fade direction="up" triggerOnce duration={500} delay={300} cascade >
        <Component.ListCards  />
      </Fade>
      </ContainerWrapper>

  );
};
