import { ButtonText, StyledButton } from "./styled";
import { TButtonProps } from "./types";

export const Button = ({
  type = "button",
  title = " ",
  onClick,
  children,
  disabled,
  maxWidth,
  width = "0px",
  height = "60px",
  ...props
}:TButtonProps) => {
  return (
    <StyledButton
      type={type}
      width={width}
      height={height}
      maxWidth={maxWidth}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      <ButtonText>{title}</ButtonText>
      {children}
    </StyledButton>
  );
};
