import { useMediaQuery } from 'react-responsive'

export const useResolution = () => {
  const isMinScreen1440 = useMediaQuery({
    query: `(min-width:1440px )`,
  })
  const isMinScreen1000 = useMediaQuery({
    query: `(min-width:1000px )`,
  })
  const isMinScreen768 = useMediaQuery({
    query: `(min-width:768px )`,
  })
  const isMinScreen455 = useMediaQuery({
    query: `(min-width:455px )`,
  })

  return { isMinScreen1440, isMinScreen1000, isMinScreen768, isMinScreen455 }
}
