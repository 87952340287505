import { Svg } from "common/assets";
import { useResolution } from "common/hooks";
import { Button } from "components/buttons";

import { Styled } from "components/Styled";

import { ButtonHover, StyledAddress, StyledStopItem } from "./styles";
import { TStopProps } from "./types";

export const StopItem = ({ item }: { item: TStopProps }) => {
  const { stop, address,href} = item;
  const { isMinScreen1440, } = useResolution();
  const handleClick = () => {
    window.open( href , "_blank");
  }
  return (
    <StyledStopItem>
        <Styled.Subtitle_2>{stop}</Styled.Subtitle_2>
        <StyledAddress>{address}</StyledAddress>
        <ButtonHover>
          <Button
            type="button"
            title="Переглянути на мапі"
            width={ isMinScreen1440 ? "250px":"100%"}
            height="52px"
          onClick={handleClick }
          >
            <img src={Svg.Arrow} alt="arrow" />
          </Button>
      </ButtonHover>
    </StyledStopItem>
  );
};
