import { Svg } from "common/assets"
import { useResolution } from "common/hooks"
import { EColors } from "common/styles"
import { Button } from "components/buttons"
import { Modal } from "components/modal"
import { Styled } from "components/Styled"
import { useState } from "react"
import { RouteModal } from "./RouteModal"
import { ButtonHover, StyledDescribeRoute, StyledImage, StyledRoute, StyledRouteItem, StyledRouteWrapper } from "./styles"
import { TRouteProps } from "./types"

export const RouteItem = ({ item }: { item: TRouteProps }) => {
    const [modalActive, setModalActive] = useState(false)
    const { isMinScreen768 }=useResolution()
    const { route, srcImage, text, src } = item


    return (
        <StyledRouteItem>
            <StyledRouteWrapper>
                <StyledImage src={srcImage} alt='city' />
                <StyledDescribeRoute>
                    <StyledRoute>{route}</StyledRoute>
                    <ButtonHover>
                        <Button
                            type="button"
                            title=""
                            width="175px"
                            height="44px"
                            onClick={() => setModalActive(!modalActive)}
                        >
                            <Styled.PBody2SB color={EColors.white}>Детальніше</Styled.PBody2SB>
                            <img src={Svg.Arrow} alt="arrow" />
                        </Button>
                    </ButtonHover>
                </StyledDescribeRoute>
            </StyledRouteWrapper>
            <Modal modalActive={modalActive} setModalActive={setModalActive} maxWidth={isMinScreen768 ? '718px' : 'calc(100% - 32px)'}>
                <RouteModal route={route} text={text} src={src} setModalActive={setModalActive} modalActive={modalActive} />
            </Modal>
        </StyledRouteItem>
    )
}