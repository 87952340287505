import { Config } from "common/config";
import { useIntersection } from "common/hooks";
import { EColors } from "common/styles";
import { Accordion } from "components/accordion/Accordion";
import { Styled } from "components/Styled";
import { useRef } from "react";
import { Fade } from "react-awesome-reveal";
import { accordionItems } from "./data";
import { StyledTitle } from "./styled";

export const FAQ = () => {
  const refAnimation = useRef(null);
  const isInteract = useIntersection({ element: refAnimation });
  return (
    <Styled.Section
      color={EColors.white}
      id={Config.Section.questions}
      ref={refAnimation}
      style={{ opacity: isInteract ? 1 : 0 }}
    >
      <Styled.Container>
        <Fade triggerOnce direction="up" duration={500} delay={300}>
          <StyledTitle>Найпопулярніші питання</StyledTitle>
        </Fade>
        <Accordion items={accordionItems} />
      </Styled.Container>
    </Styled.Section>
  );
};
