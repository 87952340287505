import axios from "axios";
import { Button } from "components/buttons";
import { InputField } from "components/input";
import { Formik } from "formik";
import { ERROR_MES, SUCCESS_MES } from "../../data";
import { ContactSchema } from "./ContactSchema";

import {
  FormWrapper,
  InputBoxWrapper,
  StyledSpanText,
  StyledTitle,
} from "./styled";
import { TFormModalProps, TFormProps } from "./types";

export const initialValues: TFormProps = {
  name: "",
  phone: "",
};

export const FormCall = ({ setMessage }: TFormModalProps) => {
  const onSubmitData = (
    values: TFormProps,
    /* eslint-disable */
    { resetForm }: { resetForm: any }
  ) => {
    const data = {
      name: values.name,
      phone: values.phone,
    };

    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("phone", data.phone);

    axios({
      method: "post",
      url: "https://mailer.kitg.com.ua/autoservicetur/book-call.php",
      data: formData,
    })
      .then((response) => {
        console.log(response.data);
        setMessage(SUCCESS_MES);
        return response.data;
      })
      .catch((error) => {
        setMessage(ERROR_MES);
        console.log("error: ", error.message);
      });

    resetForm({ values: initialValues });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={ContactSchema}
        onSubmit={onSubmitData}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <FormWrapper onSubmit={handleSubmit}>
            <StyledTitle>Замовити дзвінок</StyledTitle>
            <StyledSpanText>
              Після відправки форми найближчим часом з вами зв’яжеться наш
              менеджер.
            </StyledSpanText>
            <InputBoxWrapper>
              <InputField
                type="text"
                name="name"
                label="Ім'я"
                placeholder="Вкажіть ваше Ім’я"
                onChange={handleChange}
                value={values.name}
              />
              <InputField
                type="tel"
                name="phone"
                label="Номер телефону"
                placeholder="Зазначте номер телефону"
                onChange={handleChange}
                value={values.phone}
              />
            </InputBoxWrapper>
            <Button
              type="submit"
              title="Відправити"
              width="100%"
              maxWidth="100%"
              height="52px"
            />
          </FormWrapper>
        )}
      </Formik>
    </>
  );
};
