import { Svg } from "common/assets"
import { LinkInstagram, StyledNic } from "./styled"

export const Instagram = () => {
    return (
        <>
            <LinkInstagram href='https://instagram.com/luck_warszawa?igshid=MzRlODBiNWFlZA==' target='_blank' >
            <img src={Svg.Instagram} alt='instagram-icon' />
                <StyledNic>luck_warszawa</StyledNic>
            </LinkInstagram>
        </>
    )
}