import { EColors, EFont } from "common/styles";
import styled from "styled-components";

export const StyledCardsList = styled.ul`
list-style-type: style none;;
display:grid;
grid-template-columns:1fr 1fr;
grid-column:1fr 1fr;
@media screen and (max-width: 767px) {
display: flex;
flex-direction: column;
}

`
export const StyledCardItem = styled.li`
display: flex;
flex-direction: column;
gap:12px;
padding: 32px 0;
border-bottom:1px solid ${EColors.neutral_300};
@media screen and (min-width: 768px) {
padding: 48px 28px;
&:nth-child(odd){
  border-right:1px solid ${EColors.neutral_300};  
}
}
@media screen and (max-width: 767px) {
&:first-child{
  padding-top:0;
}
}

`
export const StyledNumber = styled.span`
font-family: ${EFont.Oswald};
font-size: 72px;
font-weight: 500;
line-height: 112%; 
letter-spacing: 0.5px; 
color:${EColors.neutral_300};
@media screen and (min-width: 768px) {
    font-size: 96px;
}
`
export const StyledTitle = styled.p`
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 28px;
  line-height: 39.2px;
  text-align: left;
  @media screen and (max-width: 767px) {
    font-size: 20px;
    line-height: 24.4px;
  }
`