import { EColors } from "common/styles";
import styled from "styled-components";

export const MenuButton = styled.div`
  border: none;
  border-radius: 100%;
  background-color: transparent;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media screen and (min-width: 1000px) {
    display:none
  }
  img {
    width: 100%;
  }
`;
