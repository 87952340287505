import { Svg } from "common/assets";
import { useResolution } from "common/hooks";
import { EColors } from "common/styles";
import { Modal } from "components/modal";
import { Styled } from "components/Styled";
import { useState } from "react";
import { ContactList } from "./ContactsList";
import { FormContactsDrivers } from "./FormContacsDrivers";
import { Instagram } from "./Instagram";
import { ButtonPhone, StyledContactsWrapper } from "./styled";

export const Contacts = () => {
  const [modalActive, setModalActive] = useState(false)
  const { isMinScreen768 }=useResolution()

  const handleClick = () => {
    setModalActive(!modalActive)
  }

  return (
    <StyledContactsWrapper>
      <Styled.PBody1SB color={EColors.neutral_600}>Контакти</Styled.PBody1SB>
      <Instagram />
      <ContactList />
      <ButtonPhone
        type="button"
        onClick={handleClick}
      >
        <Styled.PBody2SB color={EColors.white}>Телефони водіїв</Styled.PBody2SB>
        <img src={Svg.ArrowRight} alt="arrow right" />
      </ButtonPhone>
      <Modal modalActive={modalActive} setModalActive={setModalActive} maxWidth={isMinScreen768 ? '674px' : 'calc(100% - 32px)'}>
        <FormContactsDrivers/>
      </Modal>
    </StyledContactsWrapper>
  );
};
