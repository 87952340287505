import { Svg } from "common/assets";
import { EBookStatus } from "../../types";
import { Error } from "../Error";
import {
  ButtonClose,
  IconClose,
  ModalWrapper,
  StyledModal,
  StyledContainer,
  StyledIframe,
} from "./styled";
import { Success } from "../Success";
import { TModalProps } from "./types";
import { useResolution } from "common/hooks";
import { useEffect, useState } from "react";
import { fetchPaymentStatus } from "common/helpers/fetchPaymentStatus";
import { Impossible } from "../Impossible";

const isChrome = navigator.appVersion.indexOf("Chrome/") != -1;

export const ModalAnswer = ({
  status,
  setStatus,
  modalActive,
  setModalActive,
  dataTravel,
  urlRequest,
  idBooking,

}: TModalProps) => {
  const [payment, setPayment] = useState(false)
  const [paymentStatus, setPaymentStatus] = useState("");
  const { isMinScreen768 } = useResolution();

 

  const handleClick = () => {
    if (urlRequest !== "" && status === EBookStatus.success) {
      setPayment(!payment)
    }
    if (status === EBookStatus.error || payment || status === EBookStatus.impossible) {
      setModalActive(!modalActive);
      setStatus(EBookStatus.book);
      document.body.style.overflowY = "inherit";
    }
  };

  useEffect(() => {
    if (payment === true) {
      fetchPaymentStatus(idBooking, setPaymentStatus);
    }
  }, [idBooking, payment]);

  useEffect(() => {
    modalActive && isChrome && isMinScreen768 ? document.body.style.paddingRight = "17px" : document.body.style.paddingRight = "0"
  }, [setModalActive])
  
  return (
    <>
      <StyledModal active={modalActive ? "active" : ""}>
        <ModalWrapper
          active={modalActive ? "active" : ""}
          maxWidth={isMinScreen768 ? "547px" : "calc(100% - 32px)"}
        >
          <ButtonClose onClick={handleClick}>
            <IconClose>
              <use href={Svg.sprite + "#icon-close"} />
            </IconClose>
          </ButtonClose>
          <StyledContainer>
            {urlRequest !== "" && status === EBookStatus.success && !payment && (<StyledIframe src={`${urlRequest}`} />
            )}
            {status === EBookStatus.success && payment && paymentStatus === "success" && <Success dataTravel={dataTravel} />}
            {status === EBookStatus.error || payment && paymentStatus === "error" && <Error />}
            {status === EBookStatus.impossible && <Impossible />}
          </StyledContainer>
        </ModalWrapper>
      </StyledModal>
    </>
  );
};
