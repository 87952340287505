import { EColors, EFont } from "common/styles";
import { FLEX, FONT } from "common/utils";
import styled from "styled-components";

export const ButtonText = styled.span`
  ${FLEX({ align: "center" })};
  font-family:${EFont.Roboto}, sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: ${EColors.white};
  transition: all 0.3s linear;
`;

export const StyledButton = styled.button<{
  width: string;
  height: string;
  disabled?: boolean;
  maxWidth?: string;
}>`
  display: inline-flex; 
  height: 52px;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch; 
  width: 100%;
  max-width:343px;
  max-width: ${(props) => props.maxWidth};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  outline: none;
  border: none;
  border-radius: 8px;
  white-space: nowrap;
  box-sizing: border-box;
  outline: none;
  flex-shrink: 1;
  order: 1;
  align-self: stretch;
  background: ${EColors.primary_400};
  transition: all 0.3s linear;
  cursor: pointer;
  &:hover{
    background: ${EColors.primary_500};
  };
  
  &:focus ,
  &:active{
     background: ${EColors.primary_400};
  }
  @media screen and (max-width: 767px) {
    width:100%;
  }
`;
