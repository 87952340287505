import moment from 'moment';
import * as Yup from 'yup';

export const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

const dateValidation = moment().format('D MMM YYYY');

export const ContactSchema = Yup.object({
    name: Yup.string()
        .min(2,"Мінімум 2 літери")
        .required("Обо'язкове поле"),
    phone: Yup.string()
        .matches(phoneRegExp, "Невірний номер")
        .min(5, "Мінімум 5 цифр")
        .max(20, "Максимум 20 цифр")
        .required("Обо'язкове поле"),
    departure: Yup.string()
        .required("Обо'язкове поле"),
    arrival: Yup.string()
        .required("Обо'язкове поле"),
    date: Yup.date().required('Невірна дата').nullable(),
    time: Yup.string()
        .required("Обо'язкове поле")
        .test(
            "is-future",
            "Неправильний час",
            /* eslint-disable */
            function (value) {
                const { date } = this.parent;
                const selectedDateTime = new Date(date);
                selectedDateTime.setHours(parseInt(value.split(":")[0]));
                selectedDateTime.setMinutes(parseInt(value.split(":")[1]));
                return selectedDateTime > new Date();
            }
        ),
    passengers: Yup.number()
        .positive()
        .min(1,"Мінімум 1 пасажир")
        .max(10, "до 10 пасажирів")
        .required("Обо'язкове поле"),
    
    returnDate: Yup.date().nullable(),
    returnTime: Yup.string()
        .test(
            "is-future",
            "Неправильний час",
            /* eslint-disable */
            function (value) {
                if (!value) {
                    return
                }
                const { returnDate } = this.parent;
                const selectedDateTime = new Date(returnDate);
                value?selectedDateTime.setHours(parseInt(value.split(":")[0])):'';
                value?selectedDateTime.setMinutes(parseInt(value.split(":")[1])):'';
                return selectedDateTime > new Date();
            }
    ),
    passengersReturn: Yup.number()
        .positive()
        .min(1, "Мінімум 1 пасажир")
        .max(10, "до 10 пасажирів")
});