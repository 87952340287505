import { StopItem } from "./StopItem";
import { items } from "./data";
import { TStopProps } from "./types";
import { StyledButtonMoreWrapper, StyledStopsList } from "./styles";
import {  useRef, useState } from "react";
import {  useResolution } from "common/hooks";
import { Button } from "components/buttons";
import { Config } from "common/config";
import { Fade } from "react-awesome-reveal";


export const StopsList = () => {
  const [cardCount, setCardCount] = useState<number>(3);
  const { isMinScreen768 } = useResolution();
  const diff = items.length - cardCount;
  const ref = useRef<HTMLDivElement>(null)
  

  const getNextCard = (count: number) => {
    setCardCount(count + 3);
    ref.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start"
    });
   }


  const handleClick = () => {
    items.length > cardCount
      ? getNextCard(cardCount)
      : setCardCount(items.length);
    if (cardCount >= items.length) {
      setCardCount(3);
      window.location.href = `#${Config.Section.stops}`;
    }
  };
  
  return (
    <>
      {isMinScreen768 ? (
        <StyledStopsList >
          {items.map((item: TStopProps, index: number) =>
            <Fade triggerOnce cascade damping={500} delay={1000} duration={1000} key={index} direction="up">
              <StopItem item={item} />
            </Fade>
          )}
        </StyledStopsList>
      ) : (
          <>
            <StyledStopsList>
              {items.map((item: TStopProps, index: number) =>
                index < cardCount && (
                  <StopItem key={index} item={item} />
                )
              )}
            </StyledStopsList>
            <StyledButtonMoreWrapper ref={ref} style={{ display: items.length < cardCount ? "none" : "flex" }} >
              <Button
                type="button"
                width="100%"
                height="52px"
                onClick={handleClick}
                title={
                  cardCount < items.length ? "Показати наступні" : "Згорнути"
                }
              />
            </StyledButtonMoreWrapper>
          </>
      )}
    </>
  );
};
