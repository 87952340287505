import Logo from './sources/logo.png'
import BgHero from './sources/bg_hero.png'
import BgHeroBig from './sources/bg_hero@2x.png'
import RouteOne from './sources/route_1.png'
import RouteTwo from './sources/route_2.png'
import LogoFooter from './sources/logo_footer.png'
import App from './sources/app_store.png'
import Google from './sources/google_play.png'
import BgIphone from './sources/bg_iphone.png'
import BgIphoneMob from './sources/bg_iphone_mob.png'



export const Png = {
  Logo,
  BgHero,
  BgHeroBig,
  RouteOne,
  RouteTwo,
  LogoFooter,
  App,
  Google,
  BgIphone,
  BgIphoneMob,
}
