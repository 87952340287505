import { Config } from "common/config"
import { EColors } from "common/styles"
import { Social } from "components/social";
import { Styled } from "components/Styled"
import { StyledContactList, StyledInstagramWrapper } from "./styled";


export const ContactList = () => {
    return (
        <StyledContactList>
            <StyledInstagramWrapper>
                <Styled.PBody1 color={EColors.neutral_600}>Диспетчер Марія</Styled.PBody1>
                <Styled.FlexWrapper gap={12}>
                    <Styled.TextPhone color={EColors.white}>{Config.General.phone_one}</Styled.TextPhone>
                    <Social
                        linkTelegram={Config.General.linkTelegramOne}
                        linkViber={Config.General.linkViberOne}
                        linkWhatsapp={Config.General.linkWhatsappOne}
                    />
                </Styled.FlexWrapper>
            </StyledInstagramWrapper>
            <StyledInstagramWrapper>
                <Styled.PBody1 color={EColors.neutral_600}>Диспетчер Ірина</Styled.PBody1>
                <Styled.FlexWrapper gap={12}>
                    <Styled.TextPhone color={EColors.white}>{Config.General.phone_two}</Styled.TextPhone>
                    <Social
                        linkTelegram={Config.General.linkTelegramTwo}
                        linkViber={Config.General.linkViberTwo}
                        linkWhatsapp={Config.General.linkWhatsappTwo}
                    />
                </Styled.FlexWrapper>
            </StyledInstagramWrapper>
        </StyledContactList>
    )
}