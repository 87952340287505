import axios from "axios";
import {TFetchStatusProps } from "./types";
const baseURL = "https://mailer.kitg.com.ua/autoservicetur/status.php"


export const fetchPaymentStatus = async (order_id:string,setStatus: TFetchStatusProps) => {
    try {
        await axios.get(`${baseURL}?order_id=${order_id}`)
            .then((response) => setStatus(response.data.status));
    } catch (e) {
        throw Error
    }
}
