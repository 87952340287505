import { Config } from "common/config";
import { Button } from "components/buttons";
import { Styled } from "components/Styled";

import { Iframe, StyledButtonsWrapper, StyledContainer, StyledText } from "./styles";
import { TRouteModalProps } from "./types";

export const RouteModal = (props: TRouteModalProps) => {
  const { route, text, modalActive, setModalActive, src} = props;


  const handleClick = () => {
    setModalActive(!modalActive);
    window.location.href = `#${Config.Section.form}`;
  };
  return (
    <StyledContainer>
      <Styled.FlexWrapper direction="column" width="100%">
        <Styled.H3>{route}</Styled.H3>
        <StyledText>{text}</StyledText>
        <StyledButtonsWrapper>
          <Button
            type="button"
            title="Забронювати квиток"
            width="100%"
            height="52px"
            maxWidth="100%"
            onClick={handleClick}
          />
        </StyledButtonsWrapper>
      </Styled.FlexWrapper>
      <Iframe
        title="iframe"
        src={src}
      />
    </StyledContainer>
  );
};
