import { TFormProps } from "./types";

export const initialValues: TFormProps = {
  surname:"",
  name: "",
  phone: "",
  email:"",
  departure: "",
  arrival: "",
  date: "",
  time: "",
  passengers: "1",
  departureReturn: "",
  arrivalReturn: "",
  returnDate: "",
  returnTime: "",
  passengersReturn: "1",
};

export type TPrice = {
    string:number
}
export type TCity = {
    string: TPrice
}



export const exludes = [
  {
    "2024-05-04": {
      Тернопіль: ["14:20"],
      Кременець: ["15:45"],
      Дубно: ["16:40"],
      Луцьк: ["06:40","18:30"],
      Ковель: ["08:10","20:00"],
    },
    "2024-05-05": {
      Тернопіль: ["14:20"],
      Кременець: ["15:45"],
      Дубно: ["16:40"],
      Луцьк: ["06:40", "18:30"],
      Ковель: ["08:10", "20:00"],
      Варшава: ["06:10", "19:15"],
      Груєц: ["06:45", "20:00"],
      Варка: ["07:30", "20:30"],
      Люблін:["10:00","23:00"],
      Хелм:["10:50","23:30"]
    },
    "2024-05-06": {
      Луцьк: ["06:40"],
      Ковель: ["08:10"],
      Варшава: ["06:10", "19:15"],
      Груєц: ["06:45", "20:00"],
      Варка: ["07:30", "20:30"],
      Люблін: ["10:00", "23:00"],
      Хелм: ["10:50", "23:30"]
    },
    "2024-05-07": {
      Варшава: ["06:10"],
      Груєц: ["06:45"],
      Варка: ["07:30"],
      Люблін: ["10:00"],
      Хелм: ["10:50"]
    },
  }
]

const dates = Object.keys(exludes[0])
const cities = Object.values(exludes[0])


export const getValid = (chooseDate: string, chooseCity:string, chooseTime:string) => {
  const index = dates.findIndex((date) => date === chooseDate);

  if (index !== -1) {
    const items = Object.keys(cities[index]);
    const times = Object.values(cities[index]);
    const chooseIdx = items.findIndex((item) => item === chooseCity);
    if (chooseIdx !== -1) {
      const timeOptions = times[chooseIdx];
      const notValid = timeOptions.find(item => item === chooseTime)
      return notValid
    } else return  
    
  } else return  
  
};

