import { useResolution } from "common/hooks";
import { Component } from "modules/Footer/components";

import {
    StyledFooter,
    StyledFooterContainer,
} from "./styled";

export const Footer = () => {
    const { isMinScreen1000 } = useResolution();

    return (
        <StyledFooter>
            <StyledFooterContainer>
                <Component.BrandEntities />
                <Component.Contacts />
                {!isMinScreen1000 && <Component.General />}
            </StyledFooterContainer>
        </StyledFooter>
    );
};
