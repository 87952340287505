import { Styled } from "components/Styled"
import { StyledPoliticsContent } from "./styled"

export const ConditionContent = () =>{
    return(
        <StyledPoliticsContent >
            <Styled.H4>ПУБЛІЧНИЙ ДОГОВІР (ОФЕРТА)</Styled.H4>
            <Styled.PBody1SB mgBottom="16px">на замовлення, купівлю-продаж і доставку товарів</Styled.PBody1SB>
            <Styled.PBody1 mgBottom="12px">
                Цей договір є офіційною та публічною пропозицією Продавця укласти договір купівлі-продажу Товару, представленого на сайті www.autoservicetur.kitg.com.ua. Даний договір є публічним, тобто відповідно до статті 633 Цивільного кодексу України, його умови є однаковими для всіх покупців незалежно від їх статусу (фізична особа, юридична особа, фізична особа-підприємець) без надання переваги одному покупцю перед іншим. Шляхом укладення цього Договору покупець в повному обсязі приймає умови та порядок оформлення замовлення, оплати товару, доставки товару, повернення товару, відповідальності за недобросовісне замовлення та усі інші умови договору. Договір вважається укладеним з моменту оплати і отримання Покупцем від Продавця підтвердження замовлення в електронному вигляді.</Styled.PBody1>
            <Styled.PBody1SB>1.Визначення термінів</Styled.PBody1SB>
            <Styled.PBody1 >1.1.Публічна оферта (далі - «Оферта») - публічна пропозиція Продавця, адресована невизначеному колу осіб, укласти з Продавцем договір купівлі-продажу товару дистанційним способом (далі - «Договір») на умовах, що містяться в цій Оферті.</Styled.PBody1>
            <Styled.PBody1 >1.2.Товар або Послуга – об &#39; єкт угоди сторін, який був обраний  та оплачений покупцем на сайті Інтернет-магазину, або вже придбаний Покупцем у Продавця дистанційним способом.</Styled.PBody1>
            <Styled.PBody1>1.3.Інтернет-магазин – сайт Продавця за адресою www.autoservicetur.kitg.com.ua створений для укладення договорів роздрібної та оптової купівлі-продажу на підставі ознайомлення Покупця із запропонованим Продавцем описом Товару за допомогою мережі Інтернет.
            </Styled.PBody1>
            <Styled.PBody1 >1.4.Покупець – дієздатна фізична особа, яка досягла 18 років, отримує інформацію від Продавця, розміщує замовлення щодо купівлі товару, що представлений на сайті Інтернет-магазину для цілей, що не пов &#39;язані зі здійсненням підприємницької діяльності, або юридична особа чи фізична особа-підприємець.</Styled.PBody1>
            <Styled.PBody1 mgBottom="12px"> 1.5.Продавець – Товариство з обмеженою відповідальністю «АВТОСЕРВІС ТУР» (код ЄДРПОУ 44233175), юридична особа, яка створена і діє відповідно до чинного законодавства України, місцезнаходження якої: 43010, м.Луцьк, вул.Дубнівська, буд.18</Styled.PBody1>
            <Styled.PBody1SB>2.Предмет Договору</Styled.PBody1SB>
            <Styled.PBody1>2.1.Продавець зобов’язується передати у власність Покупцю Товар, а Покупець зобов’язується оплатити і прийняти Товар на умовах цього Договору.</Styled.PBody1>
            <Styled.PBody1 mgBottom="12px" >2.2. Датою укладення Договору-оферти (акцептом оферти) та моментом повного й беззаперечного прийняттям Покупцем умов Договору вважається дата заповнення Покупцем форми замовлення, розташованої на сайті Інтернет-магазину, за умови отримання Покупцем від Продавця підтвердження замовлення в електронному вигляді. У разі необхідності, за бажанням Покупця, Договір може бути оформлений у письмовій формі.</Styled.PBody1>
            <Styled.PBody1SB>3.Оформлення Замовлення</Styled.PBody1SB>
            <Styled.PBody1 >3.1.Покупець самостійно оформлює замовлення через форму «Бронювання квитків», або зробивши замовлення електронною поштою чи за номером телефону, вказаним в розділі контактів.</Styled.PBody1>
            <Styled.PBody1 >3.2.Покупець має можливість здійснювати оплату за придбані квитки через сервіс онлайн оплати LiqPay. У разі вибору цього методу оплати, він зобов &#39; язаний дотримуватися умов, передбачених LiqPay щодо оплати послуг та застосування їхніх умов і політики конфіденційності.
                <br />Алгоритм роботи.<br/>
                Клієнт обирає квиток, додає його до замовлення, натискає кнопку «Оплатити». 
                Далі обирає спосіб оплати PrivatPay (liqpay) та підтверджує платіж у Приват24. 
                LiqPay проводить платіж і показує його результат.<br/>
                Продавець має право відмовитися від передання замовлення Покупцеві у випадку, якщо відомості, вказані Покупцем під час оформлення замовлення, є неповними або викликають підозру щодо їх дійсності.
            </Styled.PBody1>
            <Styled.PBody1>3.3.При оформленні замовлення на сайті  Покупець зобов&#39; язується надати наступну обов&#39; язкову  інформацію, необхідну Продавцю для виконання замовлення:</Styled.PBody1>
            <Styled.PBody1>3.3.1.прізвище, ім&#39; я Покупця;</Styled.PBody1>
            <Styled.PBody1>3.3.2. контактний телефон.</Styled.PBody1>
            <Styled.PBody1 mgBottom="8px">3.3.3. Ідентифікаційний код для юридичної особи або фізичної-особи підприємця.</Styled.PBody1>
            <Styled.PBody1 >3.4.Найменування, кількість, артикул, ціна обраного Покупцем Товару вказуються на сайті Інтернет-магазину.</Styled.PBody1>
            <Styled.PBody1 >3.5.Якщо будь-якої із Сторін договору необхідна додаткова інформація, він має право запросити її у іншій Стороні. У разі ненадання необхідної інформації Покупцем, Продавець не несе відповідальності за надання якісної послуги Покупцю при покупці товару в інтернет-магазині.</Styled.PBody1>
            <Styled.PBody1>3.6.При оформленні замовлення через оператора Продавця (п.3.1.Цієї Оферти) Покупець зобов&#39; язується надати інформацію, зазначену в п. 3.3 – 3.4. цієї Оферти.</Styled.PBody1>
            <Styled.PBody1 >3.7.Ухвалення Покупцем умов цієї Оферти здійснюється за допомогою внесення Покупцем відповідних даних в реєстраційну форму на сайті Інтернет-магазину або при оформленні Замовлення через оператора. Після оформлення Замовлення через Оператора дані про Покупця вносяться до бази даних Продавця.</Styled.PBody1>
            <Styled.PBody1>3.8.Покупець несе відповідальність за достовірність наданої інформації при оформленні Замовлення.</Styled.PBody1>
            <Styled.PBody1 mgBottom="12px">3.9.Укладаючи Договір, тобто акцептуючи умови даної  пропозиції (запропоновані умови придбання Товару), шляхом оформлення Замовлення, Покупець підтверджує наступне:<br />
                - Покупець цілком і повністю ознайомлений, і згоден з умовами цієї пропозиції (оферти);<br />
                - він дає дозвіл на збір, обробку та передачу персональних даних, дозвіл на обробку персональних даних діє протягом усього терміну дії Договору, а також протягом необмеженого терміну після закінчення його дії.Крім цього, укладенням договору Покупець підтверджує, що він повідомлений (без додаткового повідомлення) про права, встановлених Законом України  &#34; Про захист персональних даних &#34;, про цілі збору даних, а також про те, що його персональні дані передаються Продавцю з метою можливості виконання умов цього Договору, можливості проведення взаєморозрахунків, а також для отримання рахунків, актів та інших документів.Покупець також погоджується з тим, що Продавець має право надавати доступ та передавати його персональні дані третім особам без будь-яких додаткових повідомлень Покупця з метою виконання замовлення Покупця.Обсяг прав Покупця, як суб&#39; єкта персональних даних відповідно до Закону України  &#34; Про захист персональних даних &#34; йому відомий і зрозумілий.
            </Styled.PBody1>

            <Styled.PBody1SB>4. Ціна і Доставка Товару</Styled.PBody1SB>
            <Styled.PBody1>4.1.Ціни на Товари та послуги визначаються Продавцем самостійно та вказані на сайті Інтернет-магазину. Всі ціни на Товари та послуги вказані на сайті у гривнях з урахуванням ПДВ.</Styled.PBody1>
            <Styled.PBody1>4.2.Ціни на Товари та послуги можуть змінюватися Продавцем в односторонньому порядку залежно від кон&#39; юнктури ринку. При цьому ціна окремої одиниці Товару, вартість якої оплачена Покупцем в повному обсязі, не може бути змінена Продавцем в односторонньому порядку.</Styled.PBody1>
            <Styled.PBody1>4.3.Зобов&#39; язання Покупця по оплаті Товару вважаються виконаними з моменту надходження Продавцю коштів на його рахунок.</Styled.PBody1>
            <Styled.PBody1 mgBottom="12px">4.4.Розрахунки між Продавцем і Покупцем за Товар здійснюються способами, зазначеними на сайті</Styled.PBody1>
            <Styled.PBody1SB>5. Права ті обов&#39; язки Сторін</Styled.PBody1SB>
            <Styled.PBody1>5.1.Продавець зобов&#39; язаний:</Styled.PBody1>
            <Styled.PBody1>5.1.1. Надати Покупцеві послугу у відповідності до умов цього Договору та замовлення Покупця.</Styled.PBody1>
            <Styled.PBody1 mgBottom="8px">5.1.2. Не розголошувати будь-яку приватну інформацію про Покупця і не надавати доступ до цієї інформації третім особам, за винятком випадків, передбачених законодавством та під час виконання Замовлення Покупця.</Styled.PBody1>
            <Styled.PBody1>5.2. Продавець має право:</Styled.PBody1>
            <Styled.PBody1 mgBottom="8px">5.2.1 Змінювати умови цього Договору, а також ціни на Товари та послуги, в односторонньому порядку, розміщуючи їх на сайті Інтернет-магазину. Всі зміни набувають чинності з моменту їх публікації.</Styled.PBody1>
            <Styled.PBody1>5.3 Покупець зобов&#39; язується:</Styled.PBody1>
            <Styled.PBody1>5.3.1 До моменту укладення Договору ознайомитися зі змістом Договору, умовами Договору і цінами, запропонованими Продавцем на сайті Інтернет-магазину.</Styled.PBody1>
            <Styled.PBody1 mgBottom="12px">5.3.2 На виконання Продавцем своїх зобов&#39;язань перед Покупцем останній повинен повідомити всі необхідні дані, що однозначно ідентифікують його як Покупця, і достатні для виконання Продавцем послуги.</Styled.PBody1>
            <Styled.PBody1SB>6. Повернення Товару</Styled.PBody1SB>
            <Styled.PBody1 mgBottom="12px">Більше 3 діб до відправлення: Якщо скасування відбувається більше, ніж за 3 дні до запланованого від&#39;їзду, можна очікувати повернення суми, що становить 75% від загальної вартості поїздки в українських гривнях (UAH).<br />
                Від 3 до 1 доби до відправлення: Якщо скасування відбувається від 3 до 1 доби до запланованого від &#39; їзду, можна очікувати повернення суми, яка становить 50% від вартості поїздки в українських гривнях (UAH).<br />
                Від 1 доби до 12 годин до відправлення: Якщо скасування відбувається від 1 доби до 12 годин до запланованого від &#39; їзду, можна очікувати повернення суми, яка становить 25% від вартості поїздки в українських гривнях (UAH).<br />
                Менше 12 годин до відправлення: У разі скасування менше ніж за 12 годин до запланованого від &#39; їзду, кошти за поїздку не повертаються.</Styled.PBody1>
            <Styled.PBody1SB>7. Відповідальність</Styled.PBody1SB>
            <Styled.PBody1>7.1.Продавець не несе відповідальності за неналежне, несвоєчасне виконання Замовлень і своїх зобов&#39; язань у випадку надання Покупцем недостовірної або помилкової інформації.</Styled.PBody1>
            <Styled.PBody1>7.2.Продавець і Покупець несуть відповідальність за виконання своїх зобов&#39; язань відповідно до чинного законодавства України і положень цього Договору.</Styled.PBody1>
            <Styled.PBody1 mgBottom="12px">7.3.Продавець або Покупець звільняються від відповідальності за повне або часткове невиконання своїх зобов&#39; язань, якщо невиконання є наслідком форс-мажорних обставин як: війна або військові дії, землетрус, повінь, пожежа та інші стихійні лиха, що виникли незалежно від волі Продавця і / або Покупця після укладення цього договору.Сторона, яка не може виконати свої зобов&#39; язання, негайно повідомляє про це іншу Сторону.</Styled.PBody1>
            <Styled.PBody1SB >8. Конфіденційність і захист персональних даних.</Styled.PBody1SB>
            <Styled.PBody1>8.1.Надаючи свої персональні дані на сайті Інтернет-магазину при реєстрації або оформленні Замовлення, Покупець надає Продавцеві свою добровільну згоду на обробку, використання (у тому числі і передачу) своїх персональних даних, а також вчинення інших дій, передбачених Законом України «Про захист персональних даних», без обмеження терміну дії такої згоди.</Styled.PBody1>
            <Styled.PBody1>8.2.Продавець зобов&#39; язується не розголошувати отриману від Покупця інформацію.Не вважається порушенням надання Продавцем інформації контрагентам і третім особам, що діють на підставі договору з Продавцем, в тому числі і для виконання зобов&#39; язань перед Покупцем, а також у випадках, коли розкриття такої інформації встановлено вимогами чинного законодавства України.</Styled.PBody1>
            <Styled.PBody1 mgBottom="12px">8.3.Покупець несе відповідальність за підтримання своїх персональних даних в актуальному стані.Продавець не несе відповідальності за неякісне виконання або невиконання своїх зобов&#39; язань у зв&#39; язку з неактуальністю інформації про Покупця або невідповідністю її дійсності.</Styled.PBody1>
            <Styled.PBody1SB>9. Інші умови</Styled.PBody1SB>
            <Styled.PBody1>9.1.Цей договір укладено на території України і діє відповідно до чинного законодавства України.</Styled.PBody1>
            <Styled.PBody1>9.2.Усі спори, що виникають між Покупцем і Продавцем, вирішуються шляхом переговорів. У випадку недосягнення врегулювання спірного питання шляхом переговорів, Покупець та/або Продавець мають право звернутися за вирішенням спору до судових органів відповідно до чинного законодавства України.</Styled.PBody1>
            <Styled.PBody1 mgBottom="24px">9.3.Продавець має право вносити зміни до цього Договору в односторонньому порядку, передбаченому п. 5.2.1. Договору. Крім того, зміни до Договору також можуть бути внесені за взаємною згодою Сторін в порядку, передбаченому чинним законодавством України.</Styled.PBody1>
            <Styled.H4 mgBottom="12px">АДРЕСА ТА РЕКВІЗИТИ ПРОДАВЦЯ:</Styled.H4>
            <Styled.PBody1>ТОВ «АВТОСЕРВІС ТУР»</Styled.PBody1>
            <Styled.PBody1>43010, м.Луцьк, вул.Дубнівська, буд.18</Styled.PBody1>
            <Styled.PBody1>код ЄДРПОУ 44233175</Styled.PBody1>
            <Styled.PBody1>тел.(095) 4716192</Styled.PBody1>
        </StyledPoliticsContent>
    )
}