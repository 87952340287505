import { Styled } from "components/Styled"
import { InputCheckbox, LabelSwitch } from "./styled"
import { TSwitcherProps } from "./types"

export const Switcher = ({ setCheck }: TSwitcherProps) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        /* eslint-disable */
        const check = (event.target as HTMLInputElement).checked;
        setCheck(check);
    
    }

    return (
        <Styled.FlexWrapper>
            <InputCheckbox type="checkbox" id="switch" onChange={handleChange} />
            <LabelSwitch htmlFor="switch">Toggle</LabelSwitch>
        </Styled.FlexWrapper>
        
    )
}