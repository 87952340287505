import { Styled } from "components/Styled"
import { driversArrivals, driversDeparture, titleArrival, titleDeparture } from "./data"
import { ListPhone } from "./ListPhone"
import { StyledWrapper, StyledWrapperPhone } from "./styled"

export const FormContactsDrivers = () => {
    return (
        <StyledWrapper>
            <Styled.H3 align="center">Телефони водіїв</Styled.H3>
            <StyledWrapperPhone>
                <ListPhone list={driversDeparture} title={titleDeparture} />  
                <ListPhone list={driversArrivals} title={titleArrival} />
            </StyledWrapperPhone>

        </StyledWrapper>
    )
}