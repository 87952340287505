import { useResolution } from 'common/hooks';
import { Styled } from 'components/Styled';
import { Footer } from 'modules/Footer'
import { Header } from 'modules/Header'
import { Layout } from 'modules/layouts'
import React, { useState } from "react";


export const Landing = () => {
  const [check, setCheck]=useState(false)
  const {isMinScreen768}=useResolution()
  return (
    <>
      <Header />
      <Styled.FlexWrapper direction='column' gap={check && isMinScreen768?333:0} style={{overflow:'hidden'}}>
        <Layout.Main check={check} setCheck={setCheck} />
        <Layout.About />
      </Styled.FlexWrapper>
      <Layout.Routes />
      <Layout.Stops />
      <Layout.StepsTravel />
      <Layout.FAQ />
      <Layout.LoadApp />
      <Footer /> 
    </>
  )
}
