export  const accordionItems = [
    {
        title: "Які послуги пасажирських перевезень надає ваша компанія?",
        content: "Наша компанія спеціалізується на забезпеченні якісних пасажирських перевезень з України до різних міжнародних напрямків. Ми пропонуємо зручні та безпечні автоперевезення для відпусток, службових поїздок, відвідування родичів та багато інших подорожей.",
    },
    {
        title: "Які заходи безпеки ви приймаєте для забезпечення безпеки пасажирів під час перевезень?",
        content: "Автобуси проходять техогляд, страховка для пасажирів. ",
    },
    {
        title: "Які особливості комфорту надаються пасажирам під час подорожей?",
        content: "Wi Fi, кондиціонери(в спекотну погоду), теплі батареї(в холодну пору року). На ранковий рейс Луцьк-Варка-Варшава кожна 8-ма поїздка безкоштовна. Можливість зарядити гаджети. ",
    },
    {
        title: "Чи має ваша компанія різноманітні маршрути для пасажирів?",
        content: "Два маршрути. ",
    },
    {
        title: "Як можу звернутися за консультацією чи додатковою інформацією щодо вашого сервісу?",
        content: "За консультацією можна звернутися до наших фахівців - Ірини 0508858649 або Марії  0668515595. ",
    },
];