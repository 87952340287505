import axios from "axios";
import { TFetchProps } from "./types";
const baseURL = "https://mailer.kitg.com.ua/autoservicetur/cities.php"


export const fetchCities = async (setCities:TFetchProps) => {
    try {
        await axios.get(`${baseURL}`)
            .then((response) =>setCities(response.data));
    } catch (e) {
        throw Error
    }
}