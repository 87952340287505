import { Social } from "components/social"
import { Styled } from "components/Styled"
import { LabelName } from "./styled"
import { TSocialWrapper } from "./types"

export const SocialWrapper = ({ linkTelegram, linkViber, linkWhatsapp, phone,label }: TSocialWrapper) => {
    return (
        <Styled.FlexWrapper direction="column" align="center" gap={8}>
            <LabelName>{label}</LabelName>
            <Styled.FlexWrapper gap={12}>
                <Styled.TextPhone>{phone}</Styled.TextPhone>
                <Social
                    linkTelegram={linkTelegram}
                    linkViber={linkViber}
                    linkWhatsapp={linkWhatsapp}
                />
            </Styled.FlexWrapper>
        </Styled.FlexWrapper>
    )
}