import { Svg } from "common/assets"
import { Config } from "common/config"
import { useIntersection, useResolution } from "common/hooks"
import { Button } from "components/buttons"
import { Styled } from "components/Styled"
import { useRef } from "react"
import { Fade } from "react-awesome-reveal"
import { Component } from "./components"
import { ButtonOrderWrapper } from "./styled"

export const StepsTravel = () => {
    const { isMinScreen455 } = useResolution()
    const refAnimation = useRef(null)
    const isInteract = useIntersection({ element: refAnimation })

    const handleClick = () => {
        window.location.href = `#${Config.Section.form}`;
    };
    return (
        <Styled.Section ref={refAnimation}>
            <Styled.Container style={{ opacity: isInteract ? "1" : "0", }}>
                <Fade direction="up" triggerOnce  delay={300} cascade  >
                <Styled.H2>Декілька кроків до поїздки</Styled.H2>
                    <Component.CardsList />
                </Fade>
                <ButtonOrderWrapper>
                    <Button
                        type="button"
                        title="Придбати квиток"
                        width={isMinScreen455 ? "250px" : "100%"}
                        height="52px"
                        onClick={handleClick}
                    >
                        <img src={Svg.Arrow} alt="arrow" />
                    </Button>

                    </ButtonOrderWrapper>
                
            </Styled.Container>
        </Styled.Section>
    )
    
}