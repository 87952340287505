import { Config } from "common/config";
import { MenuContext } from "common/contexts/Menu";
import { useContext, useState } from "react";
import { Container, Item, Link } from "./styled";
import { Styled } from "components/Styled";
import { CallButton } from "modules/Header/components/Social/styled";
import { SocialWrapper } from "./SocialWrapper";
import { Fade } from "react-awesome-reveal";
import { ModalCall } from "modules/modals/ModalCall";

export const Menu = () => {
  const { setIsOpen } = useContext(MenuContext);
  const [modalActive, setModalActive] = useState(false);

  const onItemClick = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Container>
        <Fade triggerOnce cascade duration={1000} direction="up">
          <Styled.FlexWrapper direction="column" gap={28} align="center">
            {Config.links.map((link, index) => (
              <Link href={link.href} onClick={onItemClick} key={index}>
                <Item>{link.title}</Item>
              </Link>
            ))}
          </Styled.FlexWrapper>
        </Fade>
        <Styled.FlexWrapper direction="column" gap={24} align="center">
          <Fade triggerOnce cascade duration={1000} delay={1000} direction="up">
            <SocialWrapper
              linkTelegram={Config.General.linkTelegramOne}
              linkViber={Config.General.linkViberOne}
              linkWhatsapp={Config.General.linkWhatsappOne}
              phone={Config.General.phone_one}
              label="Диспетчер Марія"
            />
            <SocialWrapper
              linkTelegram={Config.General.linkTelegramTwo}
              linkViber={Config.General.linkViberTwo}
              linkWhatsapp={Config.General.linkWhatsappOne}
              phone={Config.General.phone_two}
              label="Диспетчер Ірина"
            />
            <CallButton
              type="button"
              onClick={() => setModalActive(!modalActive)}
            >
              Замовити дзвінок
            </CallButton>
          </Fade>
          <ModalCall
            modalActive={modalActive}
            setModalActive={setModalActive}
          />
        </Styled.FlexWrapper>
      </Container>
    </>
  );
};
