import { Styled } from "components/Styled"
import { StyledPoliticsContent } from "views/ConditionPayment/styled"

export const RefundContent = () => {
    return (
        <StyledPoliticsContent >
            <Styled.H4 mgBottom="16px">Умови повернення від перевізника наступні:</Styled.H4>
            <Styled.PBody1 mgBottom="24px">Більше 3 діб до відправлення: Якщо скасування відбувається більше, ніж за 3 дні до запланованого від &#39; їзду, можна очікувати повернення суми, що становить 75% від загальної вартості поїздки в українських гривнях (UAH).<br /><br />
                Від 3 до 1 доби до відправлення: Якщо скасування відбувається від 3 до 1 доби до запланованого від &#39; їзду, можна очікувати повернення суми, яка становить 50% від вартості поїздки в українських гривнях (UAH).<br /><br />
                Від 1 доби до 12 годин до відправлення: Якщо скасування відбувається від 1 доби до 12 годин до запланованого від &#39; їзду, можна очікувати повернення суми, яка становить 25% від вартості поїздки в українських гривнях (UAH).<br /><br />
                Менше 12 годин до відправлення: У разі скасування менше ніж за 12 годин до запланованого від &#39; їзду, кошти за поїздку не повертаються.
            </Styled.PBody1>
        </StyledPoliticsContent>
    )
}