import { Styled } from "components/Styled"
import { StyledPoliticsContent } from "./styled"


export const AgreementContent = () => {
    return (
        <StyledPoliticsContent>
            <Styled.H4 align="center" mgBottom="24px">ПОЛІТИКА ПРИВАТНОСТІ</Styled.H4>
            <Styled.PBody1 > &nbsp; &nbsp; &nbsp; &nbsp; Ми – Товариство з обмеженою відповідальністю  ТОВ «АВТОСЕРВІС ТУР» (надалі Перевізник), зареєстроване за адресою Україна, 43010, м.Луцьк, вул.Дубнівська, 18 і ця сторінка є нашим офіційним Вебсайтом.<br/> Захист приватності користувачів нашого Вебсайту є пріоритетним для нас, зокрема в ході обробки тих персональних даних, які вони залишають на цьому Вебсайті.
                Уся особиста інформація, яку Ви залишаєте на цьому Вебсайті, обробляється лише за Вашої згоди та в порядку, передбаченому законодавством про захист персональних даних. Метою обробки є зворотній зв&#39;язок із користувачами та відповідь на їх питання і запити, а також розсилання інформаційних сповіщень у тому разі, якщо вони висловили бажання їх отримувати.
                Ми обробляємо лише ті персональні дані, які є необхідними з цією метою, а саме: імена користувачів, їхню контактну інформацію та інформацію про місце відправлення/призначення. Додаткова інформація може надаватися користувачами на їхній розсуд у змісті повідомлень, які вони залишають на Вебсайті, а також у долучених файлах, проте Перевізник не вимагає надання будь-якої іншої інформації.
                Перевізник вживає усіх необхідних технічних та організаційних заходів захисту персональних даних та процесу їх обробки, які передбачені законодавством.
                Персональні дані користувачів не передаються третім особам, крім випадків передбачених законодавством.
                <br /> &nbsp; &nbsp; &nbsp; &nbsp; Перевізник не збирає і не обробляє чутливі категорії персональних даних користувачів через цей Вебсайт. Будь-які спроби збирати такі категорії персональних даних від імені Перевізника є незаконними і не мають відношення до діяльності Перевізника та роботи цього Вебсайту.
                <br /> &nbsp; &nbsp; &nbsp; &nbsp; В ході користування Вебсайтом, Перевізник не збирає кукі-файли про поведінку користувачів на Вебсайті та не здійснює їхньої обробки.
                Користувачі, персональні дані яких обробляються, мають право знати про обробку своїх персональних даних та отримувати доступ до них, заперечувати проти їхньої обробки, вимагати в Перевізника їхньої зміни, уточнення або ж видалення та бути сповіщеними про втрату або витік персональних даних протягом 72 годин від часу виявлення такого факту.
                Перевізник обробляє персональні дані лише протягом строку необхідного для комунікації із користувачем або ж досягнення іншої легітимної мети, але не довше строку передбаченого законодавством про захист персональних даних.
                <br /> &nbsp; &nbsp; &nbsp; &nbsp; Із будь-яких питань, які стосуються обробки персональних даних, пишіть на адресу електронної пошти – avtoservistur@gmail.com  
            </Styled.PBody1>
   
        </StyledPoliticsContent>
        
    )
}