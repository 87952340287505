export const General = {
  phone_one: "(066) 851-55-95",
  phone_two: "(050) 885-86-49",
  linkTelegramOne: "https://t.me/+380668515595",
  linkViberOne: "viber://chat?number=%2B380668515595",
  linkWhatsappOne: "whatsapp://send?phone=+380668515595",
  linkTelegramTwo: "https://t.me/+380508858649",
  linkViberTwo: "viber://chat?number=%2B380508858649",
  linkWhatsappTwo: "whatsapp://send?phone=+380508858649",
};
