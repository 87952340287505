import { Png } from "common/assets";
import { EColors } from "common/styles";
import { Styled } from "components/Styled";
import styled from "styled-components";

export const StyledSectionLoadApp = styled.section`
  position: relative;
  z-index: 0;
  overflow: hidden;
  @media screen and (min-width: 768px) {
    padding-top: 91px;
    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 73vw;
      background-image: url(${Png.BgIphone});
      background-repeat: no-repeat;
      z-index: 1;
      transition: all 0.3s linear;
      
    }
  }
   @media screen and (min-width: 1440px) {
    &::after {
      left: 67.5vw;
    }
   }
   
`;
export const StyledTitle = styled(Styled.H3)`
  text-align: center;
  color: ${EColors.white};
  margin-bottom: 20px;
  @media screen and (min-width: 768px) {
    text-align: left;
  }
`;
export const StyledWrapperLoadApp = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background: ${EColors.primary_400};
  padding-top: 32.6px;

  @media screen and (min-width: 768px) {
    padding-top: 31.5px;
    padding-bottom: 31.5px;
    justify-content: flex-start;
  }
`;
export const StyledLoadingButton = styled.a`
  display: inline-flex;
  text-decoration: none;
`;
export const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-bottom: 28.6px;
  @media screen and (min-width: 768px) {
    justify-content: flex-start;
    gap: 24px;
    margin-bottom: 0;
  }
`;
export const ImageApp = styled.img`
  width: 157.224px;
  height: 45.793px;
  
  @media screen and (min-width: 768px) {
    width: 100%;
    height: 60px;
  }
`;

export const ImageIphone = styled.img`
  width: 213.612px;
  height: 229px;
`;
