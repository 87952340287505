import { Svg } from "common/assets";
import { useResolution } from "common/hooks";
import { Container, FlexWrapper } from "components/Styled/Styled";
import { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { Component } from "./components";
import { StyledHeader, Logo } from "./styled";

export const Header = () => {
  const { isMinScreen1000 } = useResolution();

  return (
    <>
      <StyledHeader>
        <FlexWrapper justify="space-between" align="center">
            <Logo src={Svg.Logo}  />
          {isMinScreen1000 &&
            <>
              <Component.Menu />
              <Component.Social />
            </>
          }
          {!isMinScreen1000 &&  <Component.RightMenu />}
        </FlexWrapper>
      </StyledHeader>
    </>
  );
};
