import { items } from "./data"
import { TCardProps } from "./types"
import { StyledCardsList } from "./styled"
import { CardItem } from "./CardItem"


export const CardsList = () => {
    return (
        <StyledCardsList>
            {items.map((item: TCardProps, index: number) => (
                <CardItem key={index} item={item} />
            ))}
        </StyledCardsList>
    )
}