import { Svg } from "common/assets"
import { Styled } from "components/Styled"
import { SocialMediaButton } from "./styled"
import { SocialProps } from "./types"

export const Social = ({ linkTelegram, linkViber, linkWhatsapp }: SocialProps) => {
    return (
        <Styled.FlexWrapper gap={12}>
            {/* Telegram */}
            <SocialMediaButton href={linkTelegram} target='_blank'>
                <img src={Svg.Telegram} />
            </SocialMediaButton>

            {/* Viber */}
            <SocialMediaButton href={linkViber} target='_blank'>
                <img src={Svg.Viber} />
            </SocialMediaButton>

            {/* Whatsapp*/}
            <SocialMediaButton href={linkWhatsapp} target='_blank'>
                <img src={Svg.Whatsapp} />
            </SocialMediaButton>
        </Styled.FlexWrapper>
    )
}