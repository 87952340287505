import { EColors, EFont } from "common/styles";
import { Styled } from "components/Styled";
import styled from "styled-components";

export const StyledContactsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media screen and (max-width: 999px) {
    align-items: center;
  }
`;
export const LinkInstagram = styled.a`
  text-decoration: none;
  display: inline-flex;
  width: 100%;
  height: 100%;
  padding: 8px;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border-radius: 8px;
  background: #313131;
`;
export const StyledNic = styled(Styled.PBody1)`
  color: ${EColors.white};
`;
export const StyledContactList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 4px;
`;

export const ButtonPhone = styled.button`
  outline: none;
  border: none;
  display: inline-flex;
  padding: 12px 20px;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px;
  background: ${EColors.black};
  cursor: pointer;
`;
export const StyledInstagramWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media screen and (max-width: 999px) {
    align-items: center;
  }
`;
export const StyledWrapper = styled.div`
display:flex;
flex-direction:column;
padding: 16px 36px 28px;
`
export const StyledWrapperPhone = styled.ul`
display: flex;
list-style:none;
margin-top:28px;
 @media screen and (max-width: 767px) {
flex-direction:column;
 }

`
export const StyledListPhone = styled.li`
list-style:none;
display: flex;
flex-direction: column;
gap:12px;
 &:first-child{
  padding-bottom:40px;
  padding-right:0;
  border-bottom:1px solid ${EColors.neutral_300};
@media screen and (min-width: 768px) {
  padding-bottom:0;
  padding-right:40px;
  border-bottom:0;
  border-right:1px solid ${EColors.neutral_300}
}}
&:last-child{
  padding-top:40px;
  padding-left:0;
@media screen and (min-width: 768px) {
   padding-top:0;
  padding-left:40px;
}
}


`
export const StyledTitle = styled.p`
font-family: ${EFont.Roboto},  serif;
font-size: 18px;
font-weight: 600;
line-height: 140%; 
color:${EColors.primary_400};
font-feature-settings: 'case' on; 
@media screen and (min-width: 768px) {
line-height: 122%;
}
`
export const StyledListItem = styled.li`
display:flex;
gap:20px;
`
export const StyledItem = styled.ul`
display:flex;
flex-direction: column;
gap:12px;
`