import { Styled } from "components/Styled"
import { StyledCardItem, StyledTitle } from "./styles"
import { TCardProps } from "./types"

export const CardItem = ({ item }: { item: TCardProps }) => {
    return (
        <StyledCardItem>
            <img src={item.srcIcon} alt='icon' />
            <StyledTitle>{item.title}</StyledTitle>
            <Styled.PBody1>{item.describe}</Styled.PBody1>
        </StyledCardItem>
    )
}