import { Config } from "common/config"
import { useIntersection } from "common/hooks"
import { EColors } from "common/styles"
import { Styled } from "components/Styled"
import { useRef } from "react"
import { Fade } from "react-awesome-reveal"
import { Component } from "./components"
import { StyledTitle } from "./styled"

export const Routes = () => {
    const ref = useRef(null)
    const isInteract = useIntersection({ element: ref })
  

    return (
        <Styled.Section id={Config.Section.routes} color={EColors.white}  >
            <Styled.Container >
                <Fade direction="up" triggerOnce delay={300} cascade style={{ opacity: isInteract ? "1" : "0", }} >
                    <StyledTitle ref={ref}>Популярні напрямки</StyledTitle>
                </Fade>
                    <Component.RoutesList />
            </Styled.Container>
        </Styled.Section>
        
    )
}