import { ReactNode } from "react";

import { MenuProvider } from "./Menu";
import { SmoothScrollProvider } from "./SmoothScroll";

type ContextProps = {
  children: ReactNode;
};

export const Context = ({ children }: ContextProps) => {
  return (
    <>

        <MenuProvider>
          <SmoothScrollProvider>{children}</SmoothScrollProvider>
        </MenuProvider>
      
    </>
  );
};
