import styled from "styled-components"

export const StyledWrapperStatus = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
gap:24px;
padding:16px 36px 76px;
`

export const ImageIcon = styled.img`
width: 127px;
height: 126px; 
`