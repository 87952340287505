import { Png } from 'common/assets'
import { useResolution } from 'common/hooks';
import { General } from '../General';
import { FlexWrapperBrand, LogoImg } from './styled';


export const BrandEntities = () => {
  const { isMinScreen1000 } = useResolution()
  return (
      <FlexWrapperBrand>
        <LogoImg src={Png.LogoFooter} />
        {isMinScreen1000 && <General/>}
      </FlexWrapperBrand>
  )
}
