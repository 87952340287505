export enum EColors {
  black = '#000000',
  white = '#ffffff',
  red = '#A3101C',
 

  neutral_100 = '#F8F8F8', 
  neutral_200 = '#F4F4F4',
  neutral_300 = '#DFDFDF',
  neutral_400 = '#CACACA',
  neutral_500 = '#B5B5B5',
  neutral_600 = '#A0A0A0',
  neutral_700 = '#8B8B8B',
  neutral_800 = '#222222',

  primary_100 = '#FFF1ED',
  primary_200 = '#FCD2D0',
  primary_300 = '#F2585D',
  primary_400 = '#ED1B24',
  primary_500 = '#A3101C',

  accent_100 = '#21AE3A',

  /*commit*/
  accent_200 = '#25BF6C',
  accent_300 = '#009846',
  accent_400 = '#057639',

  bg_dark = '#313131',
  bg_light = '#FFF1ED',
}
