import { Config } from "common/config";

export const links = [
    {
        title: "Наші напрямки",
        href: `#${Config.Section.routes}`,
    },

    {
        title: "Наші зупинки",
        href: `#${Config.Section.stops}`,
    },
    {
        title: "Питання та відповіді",
        href: `#${Config.Section.questions}`,
    },
    
]
