import { EColors } from "common/styles";
import { Styled } from "components/Styled";
import styled from "styled-components";

export const StyledStopsList = styled.ul`

display: flex;
flex-direction: column;
gap:24px;
transition: height 0.5s linear;
overflow: hidden;
@media screen and (min-width: 768px) {
display: grid;
grid-template-columns:1fr 1fr;  
}
@media screen and (min-width: 1098px) {
display: grid;
grid-template-columns:1fr 1fr 1fr;
}
@media screen and (min-width: 1440px) {
display: grid;
grid-template-columns:1fr 1fr 1fr 1fr;
}
`
export const StyledAddress = styled(Styled.PBody1)`
opacity:1;
`

export const ButtonHover = styled.div`
position:absolute;
top:68px;
opacity:0;
transition: all 0.3s linear;
`

export const StyledStopItem = styled.li`
position:relative;
display: flex;
padding: 32px 28px;
flex-direction: column;
align-items: flex-start;
gap: 16px;
min-width:306px;
min-height: 154px;
align-self: stretch; 
background: ${EColors.neutral_100};
cursor:pointer;
transition: all 0.3s linear;
&:hover ${ButtonHover} {
    opacity: 1;
};
&:hover ${StyledAddress} {
    opacity: 0;
};
`
export const StyledButtonMoreWrapper = styled.div`
justify-content: center;
margin-top:24px
`