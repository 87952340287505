import { Config } from "common/config"
import { SocialMedia } from "components/buttons/SocialMedia"
import { ModalCall } from "modules/modals/ModalCall/ModalCall";
import {  useState } from "react"
import { CallButton, ContentWrapper, SelectWrapper, SocialWrapper } from "./styled"

export const Social = () => {
    const [currentNumber, setCurrentNumber] = useState('0668515595')
    const [modalActive, setModalActive] = useState(false)


    return (
        <ContentWrapper>
        <SocialWrapper>
            <SocialMedia currentNumber={currentNumber} />
            <SelectWrapper
                defaultValue={currentNumber}
                onChange={(e: { target: HTMLSelectElement }) => { setCurrentNumber(e.target.value); }}
            >
                <option value='0668515595'>{Config.General.phone_one}</option>
                <option value='0508858649'>{Config.General.phone_two}</option>
            </SelectWrapper>
            </SocialWrapper>
            <CallButton
                type='button'
                onClick={() => setModalActive(!modalActive)}
            >
                Замовити дзвінок
            </CallButton>
            <ModalCall modalActive={modalActive} setModalActive={setModalActive} />
        </ContentWrapper>
    )
}