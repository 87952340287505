import { Styled } from "components/Styled";
import { ContainerHero, StyledMainSection, StyledTitle } from "./styled";
import { useEffect, useRef, useState } from "react";
import { Config } from "common/config";
import { TFormCheckProps } from "common/types";
import { Component } from "./component";
import { EBookStatus } from "./types";
import { TDataProps } from "./component/FormOrder/types";
import { initialData } from "./data";
import { Fade} from "react-awesome-reveal";
import { useIntersection } from "common/hooks";


export const Main = ({ check, setCheck }: TFormCheckProps) => {
  const [modalActive, setModalActive] = useState(false);
  const [status, setStatus] = useState<EBookStatus>(EBookStatus.book);
  const [dataTravel, setDataTravel] = useState<TDataProps>(initialData);
  const [idBooking, setIdBooking] = useState('')
  const [urlRequest, setUrlRequest] = useState("");
  const ref = useRef(null)
  const isInteract = useIntersection({ element: ref })

  const otherStatus = status === EBookStatus.success || status === EBookStatus.error || status === EBookStatus.impossible;
   
  if (modalActive) {
    const scrollY = document.body.style.top;
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
    document.body.style.overflowY = "hidden";
  }
  
  useEffect(() => {
    if (otherStatus){
      setModalActive(!modalActive)
    }   
  }, [status])




  return (
    <StyledMainSection id={Config.Section.form} check={check} >
      <ContainerHero >
        <StyledTitle ref={ref}>
          <Fade cascade damping={1e-1} style={{ opacity: isInteract ? "1" : "0", }}>Бронювання квитків
            </Fade>
        </StyledTitle>
        <Component.FormOrder check={check} setCheck={setCheck} setStatus={setStatus} dataTravel={dataTravel} setDataTravel={setDataTravel} setIdBooking={setIdBooking} setUrlRequest={setUrlRequest} />
          <Component.ModalAnswer status={status} setStatus={setStatus} dataTravel={dataTravel} modalActive={modalActive} setModalActive={setModalActive} urlRequest={urlRequest} idBooking={idBooking} />
        </ContainerHero>
    </StyledMainSection>
    
  );
};
