export const titleDeparture = 'Варшава - Варка - Луцьк'
export const titleArrival = 'Варшава - Луцьк - Тернопіль'

export const driversDeparture = [
    {
        phone: '(097) 740-63-26',
        name:'Саша'
    },
    {
        phone: '(050) 833-09-34',
        name: 'Іван'
    },
    {
        phone: '(050) 969-76-43',
        name: 'Леонід'
    },
    {
        phone: '(050) 526-96-83',
        name: 'Микола'
    },
    {
        phone: '(050) 534-62-47',
        name: 'Богдан'
    },
    {
        phone: '(050) 294-43-85',
        name: 'Володимир'
    },
]

export const driversArrivals = [
    {
        phone: '(050) 534-62-47',
        name: 'Богдан'
    },
    {
        phone: '(050) 294-43-85',
        name: 'Володимир'
    },
    {
        phone: '(097) 740-63-26',
        name: 'Саша'
    },
    {
        phone: '(050) 833-09-34',
        name: 'Іван'
    },
    {
        phone: '(050) 922-66-10',
        name: 'Микола'
    },
    {
        phone: '(050) 665-18-63',
        name: 'Сергій'
    },
    {
        phone: '(099) 059-72-66',
        name: 'Руслан'
    },
    {
        phone: '(095) 890-20-68',
        name: 'Коля'
    },
    {
        phone: '(050) 719-10-29',
        name: 'Коля'
    },
    
]