import * as Yup from 'yup';

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

export const ContactSchema = Yup.object({
    name: Yup.string()
        .min(2, "Мінімум 2 літери")
        .required("Обо'язкове поле"),
    phone: Yup.string()
        .required("Обо'язкове поле")
        .matches(phoneRegExp, "Невірний номер телефону")
        .min(5)
        .max(20),

});