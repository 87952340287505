import { useEffect, useState } from 'react'
import { Header } from 'modules/Header'
import { Component } from './components'
import { Container } from './styled'

export const Menu = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true)
    }, 0)
  }, [])

  return (
    <>
      <Container style={{ opacity: isVisible ? 1 : 0 }}>
        <Header />
          <Component.Menu />
      </Container>
    </>
  )
}
