import styled from "styled-components";

export const ButtonOrderWrapper = styled.div`
display: flex;
justify-content:center;
margin-top:28px;
@media screen and (min-width: 768px) {
justify-content:flex-end;
margin-top:48px;
}
`