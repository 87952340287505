import { CopyRight } from "components/CopyRight";
import styled from "styled-components";

export const FlexWrapperBrand = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
    @media screen and (max-width: 999px) {
      align-self: center;
    }
`;
export const LogoImg = styled.img`
  width: 304px;
  height: 203px;
  margin-bottom: auto;
  @media screen and (max-width: 767px) {
    width: 209px;
    height: 140px;
  }
`;
