import { Svg } from "common/assets";
import { Footer } from "views/ConditionPayment/Footer";
import { StyledConditionPage, StyledLogo } from "views/ConditionPayment/styled";
import { RefundContent } from "./RefundContent";
export const ConditionRefund = () => {
    return (
        <StyledConditionPage>
            <StyledLogo href="/">
                <img src={Svg.Logo} alt="logo" />
            </StyledLogo>
            <RefundContent />
            <Footer />
        </StyledConditionPage> 
    )
}