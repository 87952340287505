import axios from "axios";
import { TFetchProps } from "./types";
const baseURL = "https://mailer.kitg.com.ua/autoservicetur/time.php"


export const fetchTimes = async (setTimes: TFetchProps) => {
    try {
        await axios.get(`${baseURL}`)
            .then((response) => setTimes(response.data));
    } catch (e) {
        throw Error
    }
}