import { Svg } from 'common/assets'
import { useResolution } from 'common/hooks'
import { EColors } from 'common/styles'
import { Styled } from 'components/Styled'

import { ImageIcon} from '../Error/styled'
import { IconCheck } from '../FormOrder/styled'
import {  FormWrapper,StyledHalf, StyledPriceContainer, StyledPriceWrapper, StyledRow, StyledRowBorder, StyledTitle, } from './styled'
import { TDataProps } from './type'

export const Success = ({ dataTravel }: { dataTravel: TDataProps }) => {
  const { surname, name, phone, email, departure, arrival, date, time, passengers, departureReturn, arrivalReturn, returnDate, returnTime, passengersReturn,price} = dataTravel;
  const returnTravel = dataTravel.return === 'так'
  const { isMinScreen768 } = useResolution()
  
  return (
    <>
        <ImageIcon src={Svg.Success} alt='icon' />
        <Styled.H3 align='center'>Успішне оформлення!</Styled.H3>
        <Styled.PBody1 color={EColors.neutral_600} align='center'>Ви успішно оформили квиток на поїздку з нашою компанією. Дякуємо за довіру!</Styled.PBody1>
        <FormWrapper>
          <StyledRowBorder>
            <StyledHalf >
              <Styled.PBody2SB>Прізвище</Styled.PBody2SB>
              <Styled.PBody2>{surname }</Styled.PBody2>
            </StyledHalf>
            <StyledHalf>
              <Styled.PBody2SB>Телефон</Styled.PBody2SB>
              <Styled.PBody2>{name}</Styled.PBody2>
            </StyledHalf>
        </StyledRowBorder>
          <StyledRowBorder>
            <StyledHalf >
              <Styled.PBody2SB>Прізвище</Styled.PBody2SB>
              <Styled.PBody2>{phone}</Styled.PBody2>
            </StyledHalf>
            <StyledHalf>
              <Styled.PBody2SB>Телефон</Styled.PBody2SB>
              <Styled.PBody2>{email}</Styled.PBody2>
            </StyledHalf>
          </StyledRowBorder>
          <StyledRow>
            <StyledHalf>
              <Styled.PBody2SB>Місце відправлення</Styled.PBody2SB>
              <Styled.PBody2>{departure}</Styled.PBody2>
            </StyledHalf>
            <StyledHalf>
              <Styled.PBody2SB>Місце прибуття</Styled.PBody2SB>
              <Styled.PBody2>{arrival}</Styled.PBody2>
            </StyledHalf>
          </StyledRow>
          <StyledRowBorder>
            <StyledHalf>
              <Styled.PBody2SB>Дата та час</Styled.PBody2SB>
              <Styled.PBody2>{date} о { time}</Styled.PBody2>
            </StyledHalf>
            <StyledHalf>
              <Styled.PBody2SB>Пасажири</Styled.PBody2SB>
              <Styled.PBody2>{passengers}</Styled.PBody2>
            </StyledHalf>
        </StyledRowBorder>
        {returnTravel &&(
          <>
            <StyledTitle>Зворотня подорож</StyledTitle>
            <StyledRow>
              <StyledHalf>
                <Styled.PBody2SB>Місце відправлення</Styled.PBody2SB>
                <Styled.PBody2>{departureReturn}</Styled.PBody2>
              </StyledHalf>
              <StyledHalf>
                <Styled.PBody2SB>Місце прибуття</Styled.PBody2SB>
                <Styled.PBody2>{arrivalReturn}</Styled.PBody2>
              </StyledHalf>
            </StyledRow>
            <StyledRow>
              <StyledHalf>
                <Styled.PBody2SB>Дата та час</Styled.PBody2SB>
                <Styled.PBody2>{returnDate} о {returnTime}</Styled.PBody2>
              </StyledHalf>
              <StyledHalf>
                <Styled.PBody2SB>Пасажири</Styled.PBody2SB>
                <Styled.PBody2>{passengersReturn}</Styled.PBody2>
              </StyledHalf>
            </StyledRow>
          </>
        )}
        <StyledPriceWrapper>
          <StyledPriceContainer>
            <Styled.PBody2 color={EColors.neutral_600}>Вартість поїздки:</Styled.PBody2>
            <Styled.FlexWrapper direction='column' align={isMinScreen768 ? "flex-start" : "center"} gap={4}>
              <Styled.Subtitle_1>{price} грн</Styled.Subtitle_1>
              {returnTravel && (
                <Styled.FlexWrapper gap={10} >
                <IconCheck src={Svg.Success} alt='icon check' />
                <Styled.PBody2>Включно зі зворотньою</Styled.PBody2>
                </Styled.FlexWrapper>
                )}
            </Styled.FlexWrapper>
          </StyledPriceContainer>
        </StyledPriceWrapper>
        </FormWrapper>
    </>
  )
}
