import { EColors } from "common/styles";
import { Button } from "components/buttons";
import { Styled } from "components/Styled";
import styled from "styled-components";

export const ContainerForm = styled.div`
  display: relative;

`;
export const StyledFormWrapper = styled.div<{
  check?: boolean,
}>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  padding: 28px;
  background: ${EColors.white};
  box-shadow: 0px 12px 24px 0px rgba(166, 166, 166, 0.2);
  opacity: 0;
  transform: translateX(-100%);
  animation: leftAppearence 0.5s ease-out  forwards;
  @keyframes leftAppearence  {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translateX(0%);
    }
  }
  
  @media screen and (max-width: 1439px) {
    max-width: 599px;
  }
  @media screen and (max-width: 767px) {
    max-width: 100%;
    padding: 16px;
  }
  @media screen and (min-width: 1440px) {
    width: 42vw;
  }
  @media screen and (min-width: 768px) {
    position: ${({ check }) => check ? "absolute" : "relative" };
  }

`;
export const SwitcherWrapper = styled.div`
  display: flex;
  width: 260px;
  align-items: center;
  gap: 28px;
  margin-bottom: 28px;
`;
export const FormWrapper = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 28px;
`;
export const InputBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 23px;
  padding-bottom: 28px;
  border-bottom: 1px solid ${EColors.neutral_300};
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;
export const SelectBoxWrapper = styled(InputBoxWrapper)`
  border-bottom: none;
  padding-bottom: 0;
`;
export const InputDataWrapper = styled(InputBoxWrapper)`
  border-bottom: none;
  padding-bottom: 0;
`;
export const ConfirmedWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: flex-end;
  align-self: stretch;
  gap: 24px;
  background: ${EColors.neutral_200};
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;
export const TextWrapper = styled(Styled.PBody1)`
  color: ${EColors.neutral_600};
  text-align: center;
  @media screen and (min-width: 768px) {
    text-align: start;
  }
`;
export const PriceWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: center;
  @media screen and (min-width: 768px) {
    width: 50%;
    text-align: start;
  }
`;
export const NumberWrapper = styled(Styled.Subtitle_1)`
  text-align: center;
  @media screen and (min-width: 768px) {
    text-align: start;
  }
`;
export const ButtonWrapper = styled.div`
  width: 100%;
  @media screen and (min-width: 768px) {
    width: 50%;

  }
  @media screen and (max-width: 767px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export const ReturnWrapper = styled.div<{
  check: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding-top: 28px;
  border-top: 1px solid ${EColors.neutral_300};
  transition: all 0.5s linear;
  animation:${({ check}) => check ? "sizeHeight 2000s linear 0.3s" : "" };
  @keyframes sizeHeight {
    0% {
      height: 0;
    }
    50%{
       height: 50%;
    }
    100% {
      height: 100%;
    }
  }
`;

export const IconCheck = styled.img`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
`;
export const StyledWrapperInclude = styled.div`
display:flex;
gap:10px;
margin-top:4px;
@media screen and (max-width: 767px) {
  justify-content: center;
}
`
export const InputMobWrapper = styled.div`
display: flex;
gap:23px;
@media screen and (max-width: 767px) {
  justify-content: space-between;
  gap:16px;
}
`
export const ButtonForm=styled(Button)`
  @media screen and (max-width: 767px) {
    height:52px;
  }
`