import { Styled } from "components/Styled";
import { StyledCardItem, StyledNumber, StyledTitle } from "./styled";
import { TCardProps } from "./types";

export const CardItem = ({ item }: { item: TCardProps }) => {
  const { number, title, text } = item;
  return (
    <StyledCardItem>
      <StyledNumber>{number}</StyledNumber>
      <StyledTitle>{title}</StyledTitle>
      <Styled.PBody1>{text}</Styled.PBody1>
    </StyledCardItem>
  );
};
