import { EColors, EFont } from "common/styles";
import { Styled } from "components/Styled";
import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  z-index: 12;
  width: 100%;
  height: calc(100vh - 86px);
  padding:74px 16px 54px;
  display: flex;
  flex-direction: column;
  align-items: center;
 justify-content: space-between;

  @media screen and (min-width: 1000px) {
    display: none;
  }
`;

export const Link = styled.a`
  text-decoration: none;
  position: relative;
  &:after {
    position: absolute;
    display: block;
    content: "";
    width: 0%;
    height: 1px;
    background: ${EColors.primary_500};
    position: absolute;
    bottom: -4px;
    left: 0;
    transition: all 0.2s ease;
  }
  &:hover:after {
    width: 100%;
    background: ${EColors.primary_500};
  }
`;

export const Item = styled.p`
  font-family:${EFont.Oswald};
  font-weight: 500;
  font-size: 28px;
  line-height: 41px; 
  color: ${EColors.neutral_800};
  transition: color 0.3s linear;
  cursor: pointer;
  text-align: center;
  &:hover {
    color: ${EColors.primary_500};
  }
`;


export const LabelName = styled(Styled.PBody1)`
color:${EColors.neutral_600};
`
