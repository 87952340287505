
export const GOOGLE_KEY = 'AIzaSyAMU6EL3xzry7oAYUjpBJ9SXIHIXcwWzL8';
export const items = [
    {
        stop: "Варка",
        address: "ul. Pulawskiego 2",
        href: "https://goo.gl/maps/5tqP55Ji4LSgigQW6"
    },
    {
        stop: "Варшава",
        address: "автостанція 'Західна' al.Jerozolimskie 144",
        href: "https://www.google.com.ua/maps/place/Warszawa+Bus+Station+Zachodnia/@52.2131749,20.9725349,14.38z/data=!4m6!3m5!1s0x471ecb58a9872373:0xf16eebc1f7c8277f!8m2!3d52.2177303!4d20.9630401!16s%2Fg%2F11g69_3wh8?entry=ttu"
    },
    {
        stop: "Груєць",
        address: "зупинка Поштова ul.Niepodleglosci 6",
        href: "https://goo.gl/maps/ahAaWLV8GaGh5Sz89",
    },
    {
        stop: "Дубно",
        address: "вул. Забрама 26 АС",
        href:  "https://goo.gl/maps/MqmcX8ZsTbGMfjsF8",
    },
    {
        stop: "Козеніце",
        address: "автостанція ul.Warszawska 50",
        href: "https://goo.gl/maps/vxv6oTZvkwej5ePH9",
    },
    {
        stop: "Ковель",
        address: "вул.Варшавська 1, UPG",
        href: "https://goo.gl/maps/tmBCQnq4MzLXxomr9",
    },
    {
        stop: "Кременець",
        address: "вул. Дубенська 138 АС",
        href:"https://goo.gl/maps/Q1MapvZXa3u6Amf8A"
    },
    {
        stop: "Луцьк",
        address: "вул.Конякіна 24а АЗК БРСМ",
        href: "https://goo.gl/maps/MCUoZvs3JH75Rd8F7",
    },
    {
        stop: "Люблін",
        address: "автостанція al.Tysiaclecia 6",
        href: "https://goo.gl/maps/r9rGfFh3u4VrMDwu8",
    },
    {
        stop: "Млинів",
        address: "вул Рівненська 1 АС",
        href:"https://goo.gl/maps/Vn7W4ij9g8nk6jWX7"
    },
    {
        stop: "Пулави",
        address: "ul.Lubelska траса DW874 Lidl",
        href: "https://www.google.com.ua/maps/search/Lubelska+DW874,Lidl++%D0%9F%D1%83%D0%BB%D0%B0%D0%B2%D0%B8,+%D0%9F%D0%BE%D0%BB%D1%8C%D1%89%D0%B0/@51.4141074,21.9912888,18.33z?entry=ttu",
    },
    {
        stop: "Тернопіль",
        address: "вул.Живова 7 АС",
        href:"https://goo.gl/maps/FsdNyqRXjq6byu6z8"
    },
   
]