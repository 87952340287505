import Telegram from './sources/telegram.svg'
import Viber from './sources/viber.svg'
import Whatsapp from './sources/whatsapp.svg'
import Instagram from './sources/instagram.svg'
import ArrowDown from './sources/arrow-down.svg'
import ArrowUp from './sources/arrow_up.svg'
import ArrowAccordion from './sources/arrow_accordion.svg'
import Love from './sources/love.svg'
import Mobile from './sources/mobile.svg'
import Travel from './sources/travel.svg'
import ArrowRight from './sources/arrow-right.svg'
import Cross from './sources/close.svg'
import Menu from './sources/menu.svg'
import Logo from './sources/logo.svg'
import sprite from './sources/sprite.svg'
import Arrow from './sources/arrow.svg'
import Calendar from './sources/calendar.svg'
import Clock from './sources/clock.svg'
import Success from './sources/success.svg'
import Error from './sources/error.svg'
import Apple from './sources/apple.svg'
import Google from './sources/google.svg'


export const Svg = {
  Telegram,
  Viber,
  Whatsapp,
  Instagram,
  ArrowDown,
  ArrowUp,
  ArrowAccordion,
  Cross,
  Menu,
  Logo,
  sprite,
  Love,
  Mobile,
  Travel,
  Arrow,
  ArrowRight,
  Calendar,
  Clock,
  Success,
  Error,
  Apple,
  Google
}
