import { EColors } from "common/styles";
import { Styled } from "components/Styled";
import styled from "styled-components";

export const StyledListCards = styled.ul`
list-style:none;
display: flex;
flex-direction: column;
gap:24px;
@media screen and (min-width: 768px) {
flex-direction: row;
justify-content: space-between;
border-bottom:1px solid ${EColors.neutral_300};
}

`
export const StyledCardItem = styled.li`
width:100%;
padding:32px 28px;
&:not(:last-child){
    border-right:1px solid ${EColors.neutral_300};
}
@media screen and (min-width: 768px) and  (max-width: 1440px){
    width:416px; 
}
@media screen and (max-width: 767px) {
    padding:32px 0;
    &:not(:last-child){
    border-right:none;
    border-bottom:1px solid ${EColors.neutral_300};
}
}
`
export const StyledTitle = styled.h3`
font-family: "Roboto", serif;
font-size: 20px;
font-weight: 600;
line-height: 122%;
margin:16px 0;
`