import {  RouteItem } from "./RouteItem"
import { items } from "./data"
import { TRouteProps } from "./types"
import { StyledRoutesList } from "./styles"

export const RoutesList = () => {
    return (
        <StyledRoutesList>
            {items.map((item: TRouteProps, index: number) => (
                <RouteItem key={index} item={item} />
            ))}
        </StyledRoutesList>
    )
}