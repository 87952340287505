import { EColors } from "common/styles";
import styled from "styled-components";
export const StyledModal = styled.div<{
  active: string;
}>`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.44);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${({ active }) => (active === "active" ? "1" : "0")};
  pointer-events: ${({ active }) => (active === "active" ? "all" : "none")};
`;

export const IconClose = styled.svg`
  width: 40px;
  height: 40px;
  fill: #a0a0a0;
`;

export const ModalWrapper = styled.div<{
  active: string;
  maxWidth?: string;
}>`
  position: absolute;
  top: 130px;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth};
  height: auto;
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  background: ${EColors.white};
  visibility: ${({ active }) => (active === "active" ? "visible" : "none")};
  transform: ${({ active }) => (active === "active" ? "scale(1)" : "scale(0)")};
  border-radius: 16px;
  box-shadow: 0px 12px 24px 0px rgba(166, 166, 166, 0.2);
  transition: all 0.5s linear 0.2s;
  z-index: 16;
  @media screen and (max-width: 767px) {
    top: 102px;
  }
`;

export const ButtonClose = styled.button`
  outline: none;
  border: none;
  background: transparent;
  position: absolute;
  top: 20px;
  right: 36px;
  cursor: pointer;
  z-index: 17;
`;

export const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 16px 32px 28px;
  @media screen and (max-width: 767px) {
    padding: 16px 16px 28px;
  }
`;
export const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: none;
`;

export const StyledFormLiqpay = styled.div`
width:100%;
height: 100%;
display:flex;
justify-content:center;
align-items: center;
`
export const StyledIframe = styled.iframe`
width:100%;
height: 640px;
display: flex;
justify-content: center;
border:none;
outline:none;
`