import { Svg } from 'common/assets'
import { Config } from 'common/config'
import { Styled } from 'components/Styled'
import { useState } from 'react'
import { SocialMediaButton } from './styled'

type SocialMediaProps = {
  gap?: number
  currentNumber:string
}

export const SocialMedia = ({ gap = 8, currentNumber ='0668515595' }: SocialMediaProps) => {
  const linkTelegram = 'https://t.me/+38' + `${currentNumber}`
  const linkViber = 'viber://chat?number=%2B38' + `${currentNumber}`
  const linkWhatsapp = 'whatsapp://send?phone=+38' + `${currentNumber}`
  
  return (
    <>
      <Styled.FlexWrapper gap={gap}>
        {/* Telegram */}
        <SocialMediaButton href={linkTelegram} target='_blank'>
          <img src={Svg.Telegram} />
        </SocialMediaButton>

        {/* Viber */}
        <SocialMediaButton href={linkViber} target='_blank'>
          <img src={Svg.Viber} />
        </SocialMediaButton>

        {/* Whatsapp*/}
        <SocialMediaButton href={linkWhatsapp} target='_blank'>
          <img src={Svg.Whatsapp} />
        </SocialMediaButton>
      </Styled.FlexWrapper>
    </>
  )
}
