import { Navigate, Route, Routes } from 'react-router-dom';
import { ConditionPayment, ConditionRefund, Landing, PrivacyPolicy} from 'views'



export const AppRouter = () => {
    return (
        <Routes>
            <Route path='/' element={<Landing />} />
            <Route path='/policy' element={<PrivacyPolicy />} />
            <Route path='/conditions' element={<ConditionPayment />} />
            <Route path='/refunds' element={<ConditionRefund />} />
            <Route path='/' element={<Navigate replace to='/' />} />
        </Routes>  
    )
}
