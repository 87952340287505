import { EColors, EFont } from "common/styles";
import { Styled } from "components/Styled";
import styled from "styled-components";

export const ContainerWrapper = styled(Styled.Container)`
padding-top:68px;
padding-bottom:128px;
@media screen and (max-width: 767px) {
padding-bottom:75px;
}
`