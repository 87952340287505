export const initialData = {
    surname:"",
    name: "",
    phone: "",
    email:"",
    departure: "",
    arrival: "",
    date: "",
    time: "",
    passengers: "",
    return: "",
    departureReturn: "",
    arrivalReturn: "",
    returnDate: "",
    returnTime: "",
    passengersReturn: "",
    price: "",
}