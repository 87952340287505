import axios from "axios";
import moment from "moment";
import 'moment/min/locales.min'
import {  Svg } from "common/assets";
import { InputField, SelectField } from "components/input";
import { Styled } from "components/Styled";
import { Switcher } from "components/switcher";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { getValid, initialValues } from "./data";
import * as Yup from "yup";
import {
  ButtonForm,
  ButtonWrapper,
  ConfirmedWrapper,
  ContainerForm,
  FormWrapper,
  IconCheck,
  InputBoxWrapper,
  InputDataWrapper,
  InputMobWrapper,
  NumberWrapper,
  PriceWrapper,
  ReturnWrapper,
  SelectBoxWrapper,
  StyledFormWrapper,
  StyledWrapperInclude,
  SwitcherWrapper,
  TextWrapper,
} from "./styled";

import { useResolution } from "common/hooks";
import { TForm, TFormProps, TOption } from "./types";
import { phoneRegExp } from "./ContactSchema";
import { EBookStatus } from "../../types";
import { fetchCities } from "common/helpers/fetchCities";
import { fetchTimes } from "common/helpers/fetchTime";



export const FormOrder = ({
  check,
  setCheck,
  setStatus,
  setDataTravel,
  setIdBooking,
  setUrlRequest
}: TForm) => {
  const [cities, setCities] = useState([])
  const[times,setTimes]=useState([])
  const [departures,setDepartures]=useState<TOption[]>([])
  const [arrivals, setArrivals] = useState<TOption[]>([])
  const [arrival,setArrival]=useState('')
  const [point,setPoint]=useState("")
  const {isMinScreen1440, isMinScreen768 } = useResolution();
  const [priceTicket, setPriceTicket] = useState<number>(0);
  const [priceOne, setPriceOne] = useState<number>(0);
  const [passDeparture, setPassDeparture] = useState<number>(1);
  const [passArrival, setPassArrival] = useState<number>(1);
  const [timesOption, setTimesOption] = useState<TOption[]>([])
  const [timesReturnOptions, setTimesReturnOption] = useState<TOption[]>([])
  const [chooseTime, setChooseTime] = useState('');
  const [chooseReturnTime, setChooseReturnTime] = useState('');
 
  const departuresCity = Object.keys(cities);
  const arrivalsCity = Object.entries(cities);
  const arrivalCities = arrivalsCity.filter(el => el[0] === `${point}`).map(item => item);
 
  



  const ContactSchema = Yup.object({
    surname: Yup.string().min(2, "Мінімум 2 літери").required("Обо'язкове поле"),
    name: Yup.string().min(2, "Мінімум 2 літери").required("Обо'язкове поле"),
    phone: Yup.string()
      .matches(phoneRegExp, "Невірний номер")
      .min(5, "Мінімум 5 цифр")
      .max(20, "Максимум 20 цифр")
      .required("Обо'язкове поле"),
    email: Yup.string().min(2, "Мінімум 2 літери").required("Обо'язкове поле"),
    departure: Yup.string().required("Обо'язкове поле"),
    arrival: Yup.string().required("Обо'язкове поле"),
    date: Yup.date().required("Невірна дата").nullable()
      .test(
        "is-future",
        "Неможлива дата",
        /* eslint-disable */
        function (value) {
          if (!value) {
            return;
          }
          const now = moment.utc().subtract(24, "hours");
          const selectedDate = moment(value).utc();
          return selectedDate >= now
        }
      ),
    time: Yup.string()
      .required("Обо'язкове поле")
      .test(
        "is-future",
        "Неправильний час",
        /* eslint-disable */
        function (value) {
          /* eslint-disable */
          const {date}= this.parent;
          /* eslint-disable */
          const selectedDateTime = new Date(date);
          selectedDateTime.setHours(parseInt(value.split(":")[0]));
          selectedDateTime.setMinutes(parseInt(value.split(":")[1]));
          return selectedDateTime > new Date();
        }
    ),
    
    passengers: Yup.number()
      .positive()
      .min(1, "Мінімум 1 пасажир")
      .max(10, "до 10 пасажирів")
      .required("Обо'язкове поле"),
    ...(check
      ? {
          returnDate: Yup.date()
            .nullable()
            .test(
              "is-future",
              "Неможлива дата",
              /* eslint-disable */
              function (value) {
                if (!value) {
                  return;
                }
                const {date} = this.parent;
                const dateDep = moment(date);
                const selectedDate = moment(value);
                return selectedDate > dateDep
                
              }
            ),
          returnTime: Yup.string()
          .required("Обо'язкове поле"),
          passengersReturn: Yup.number()
            .positive()
            .min(1, "Мінімум 1 пасажир")
            .max(10, "до 10 пасажирів"),
        }
      : {}),
  });

  const onSubmitData = (
    values: TFormProps,
    /* eslint-disable */
    { resetForm }: { resetForm: any }
  ) => {
    if (priceTicket === 0) {
      return
    };

    const orderDepartureDayValue = moment(`${values.date}`, "YYYY-MM-DD")
    const orderReturnDayValue = moment(`${values.returnDate}`, "YYYY-MM-DD")
    const orderReturnDay = values.returnDate? orderReturnDayValue.locale("uk").format("D MMM, YYYY"): "";

    const data = {
      surname:values.surname,
      name: values.name,
      phone: values.phone,
      email: values.email,
      departure: values.departure,
      arrival: values.arrival,
      date: orderDepartureDayValue.locale("uk").format("D MMM, YYYY"),
      time: chooseTime !== '' ? chooseTime :"Згідно розкладу",
      passengers: String(values.passengers),
      return: check ? "так" : "ні",
      departureReturn: check ? `${values.arrival}` : "",
      arrivalReturn: check ? `${values.departure}` : "",
      returnDate: orderReturnDay,
      returnTime: chooseReturnTime !== '' ? chooseReturnTime : "Згідно розкладу",
      passengersReturn: check ? `${values.passengersReturn}` : "0",
      price: String(priceTicket),
    };

    setStatus(EBookStatus.impossible);
    // const impossibleDeparture = getValid(orderDepartureDayValue.format("YYYY-MM-DD"), data.departure, data.time);
    // const impossibleReturn = getValid(orderReturnDayValue.format("YYYY-MM-DD"), data.departureReturn, data.returnTime)
    // if (!check && impossibleDeparture) {
    //   setStatus(EBookStatus.impossible);
    // } else if ((check && (impossibleDeparture || impossibleReturn)) || (check && (impossibleDeparture && impossibleReturn))) {
    //   setStatus(EBookStatus.impossible);
    // } else {
    //   const formData = new FormData();
    //   formData.append("surname", data.surname);
    //   formData.append("name", data.name);
    //   formData.append("phone", data.phone);
    //   formData.append("email", data.email);
    //   formData.append("from", data.departure);
    //   formData.append("to", data.arrival);
    //   formData.append("date", data.date);
    //   formData.append("time", data.time);
    //   formData.append("passengers", data.passengers);
    //   formData.append("returnTrip", data.return);
    //   formData.append("returnDate", data.returnDate);
    //   formData.append("returnTime", data.returnTime);
    //   formData.append("passengersReturn", data.passengersReturn);

    //   axios({
    //     method: "post",
    //     url: 'https://mailer.kitg.com.ua/autoservicetur/order.php',
    //     data: formData,
    //   })
    //     .then((response) => {
    //       const result = response.data;
    //       setIdBooking(result.order_id)
    //       setStatus(EBookStatus.success)
    //       setDataTravel(data);
    //       setUrlRequest(`${result.url}?data=${result.data}&signature=${result.signature}`);
    //       return response.data;
    //     })
    //     .catch((error) => {
    //       setStatus(EBookStatus.error)
    //       console.log("error: ", error.message);
    //     });

    // }

   
    resetForm({ values: initialValues });
    setPriceTicket(0);
    setPoint('')
    setArrival('')
    setArrivals([])
    setPriceOne(0)
    setPassDeparture(1)
    setPassArrival(1)
    setTimesOption([])
    setTimesReturnOption([])
  };


  useEffect(() => {
    fetchCities(setCities)
  }, []);

  useEffect(() => {
    fetchTimes(setTimes)
  }, []);
 
 useEffect(() => {  
   setChooseTime('')
   setChooseReturnTime('')
    const timePoints = Object.keys(times)
   const index = timePoints.findIndex(el => el === `${point}`);
   const indexReturn = timePoints.findIndex(el => el === `${arrival}`);
    
    if (index !== -1) {
      const optArr = Object.values(times)[index];
      const timePoint = [...optArr].map(time => time).map(time => {
        return {
          label: time,
          value: time
        }
      })
      setTimesOption(timePoint)
   } 
   if (indexReturn !== -1) {
     const optReturnArr = Object.values(times)[indexReturn];
     const timeReturnPoint = [...optReturnArr].map(time => time).map(time => {
       return {
         label: time,
         value: time
       }
     })
     setTimesReturnOption(timeReturnPoint)
   } 
 }, [point, arrival, setTimesOption, setTimesReturnOption, times]);

  useEffect(() => { 
    let totalPrice = 0;
    if (check) {
      totalPrice = (priceOne * passDeparture) + (priceOne * passArrival) 
      setPriceTicket(totalPrice)
      if (point === arrival) {
        setPriceTicket(0)
      }
    } else {
      totalPrice = priceOne * passDeparture
      setPriceTicket(totalPrice)
      if (point === arrival) {
        setPriceTicket(0)
      }
    }
  }, [priceOne, passDeparture,  check, passArrival,point,arrival]);

  useEffect(() => {
    if (arrival !== '' ) {
      const arr = arrivalCities[0].map(el => Object.entries(el))[1];
      if (!arrivalCities[0].includes(`${arrival}`)) {
        setPriceOne(0)
      } 
      for (let j = 0; j < arr.length; j++) {
        if (arr[j][0] === `${arrival}`) {
          const priceOneWay = arr[j][1];
          setPriceOne(Number(priceOneWay))
        }
      }
    } 
  }, [arrival,point]);

  useEffect(() => {
    if (point !== '') {
      const keys = arrivalCities[0].map(el => Object.keys(el))[1];
      const arrivalsOption = keys.map(el => {
        return {
          label: el,
          value: el
        }
      })
      setArrivals(arrivalsOption);
    };
  }, [point,arrival]);
 
  useEffect(() => {
    const departuresOption = departuresCity.map(el=>el).map(el => {
      return {
        label: el,
        value: el
      }
    });
    setDepartures(departuresOption)
  }, [setDepartures, cities]);

  return (
    <>
      <ContainerForm>
        <StyledFormWrapper check={check}>
          <SwitcherWrapper>
            <Styled.PBody1>Зворотня подорож</Styled.PBody1>
            <Switcher setCheck={setCheck} />
          </SwitcherWrapper>
          <Formik
            initialValues={initialValues}
            validationSchema={ContactSchema}
            onSubmit={onSubmitData}
          >
            {({ values, handleChange, handleSubmit, setFieldValue ,isSubmitting}) => (
              <FormWrapper onSubmit={handleSubmit}>
                <InputBoxWrapper>
                  <InputField
                    type={"text"}
                    name="surname"
                    label="Прізвище"
                    placeholder="Вкажіть ваше прізвище"
                    onChange={handleChange}
                    value={values.surname}
                  />
                  <InputField
                    type={"text"}
                    name="name"
                    label="Ім'я"
                    placeholder="Вкажіть ваше ім'я"
                    onChange={handleChange}
                    value={values.name}
                  />
                  </InputBoxWrapper>
                <InputBoxWrapper>
                  <InputField
                    type={"tel"}
                    name="phone"
                    label="Телефон"
                    placeholder="Зазначте номер телефону"
                    onChange={handleChange}
                    value={values.phone}
                  />
                  <InputField
                    type={"email"}
                    name="email"
                    label="Е-mail"
                    placeholder="Вкажіть ваш e-mail"
                    onChange={handleChange}
                    value={values.email}
                  />
                </InputBoxWrapper>
                <SelectBoxWrapper>
                  <SelectField
                    id="departure"
                    name="departure"
                    label="Місце відправлення"
                    placeholder="Оберіть місце відправки"
                    options={departures}
                    onChange={(option) => {                 
                      setFieldValue("departure",(option as any).value)
                      setPoint(()=>(option as any).value)
                    }}
                  />
                  <SelectField
                    id="arrival"
                    name="arrival"
                    label="Місце прибуття"
                    placeholder="Оберіть місце прибуття"
                    options={arrivals}
                    onChange={(option) => {
                      setFieldValue("arrival", (option as any).value)
                      setArrival(()=> (option as any).value)
                    }}
                  />
                </SelectBoxWrapper>
                <InputDataWrapper>
                  {!isMinScreen768 ? (
                    <>
                      <InputMobWrapper>
                        <InputField
                          type={"date"}
                          name="date"
                          label="Дата"
                          bgImage={Svg.Calendar}
                          value={values.date}
                          onChange={handleChange}
                        />
                        <SelectField
                          id="time"
                          name="time"
                          label="Час відправлення"
                          placeholder="--:--"
                          options={timesOption}
                          onChange={(option) => {
                            setFieldValue("time", (option as any).value)
                            setChooseTime(() => (option as any).value)
                          }}
                        />
                      </InputMobWrapper>
                      <InputField
                        type={"text"}
                        name="passengers"
                        label="Пасажири"
                        bgImage={Svg.ArrowDown}
                        value={values.passengers}
                        onChange={(event) => {
                          const pass = event.target.value
                          setFieldValue("passengers", pass);
                          setPassDeparture(Number(`${pass}`)) 
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <InputField
                        type={"date"}
                        name="date"
                        label="Дата"
                        bgImage={Svg.Calendar}
                        value={values.date}
                        onChange={handleChange}
                      />                     
                      <SelectField
                        id="time"
                        name="time"
                        label="Час відправлення"
                        placeholder="--:--"
                        options={timesOption}
                        onChange={(option) => {
                          setFieldValue("time", (option as any).value)
                          setChooseTime(() => (option as any).value)
                        }}
                      />
                      <InputField
                        type={"number"}
                        name="passengers"
                        label="Пасажири"
                        bgImage={Svg.ArrowDown}
                          value={values.passengers}
                          onChange={(event) => {
                            const pass = event.target.value
                            setFieldValue("passengers", pass)
                            setPassDeparture(Number(`${pass}`))
                          }}
                      />
                    </>
                  )}
                </InputDataWrapper>
                {check && (
                  <ReturnWrapper
                    check={check }
                    style={{ display: check ? "flex" : "none" }}
                  >
                    <SelectBoxWrapper>
                      <SelectField
                        id="departureReturn"
                        name="departureReturn"
                        label="Місце відправлення"
                        placeholder={values.arrival}
                        options={departures}
                        value={values.arrival}
                        disabled
                        isDisabled
                        onChange={() => console.log(values.arrival)}
                      />
                      <SelectField
                        id="arrivalReturn"
                        name="arrivalReturn"
                        label="Місце прибуття"
                        placeholder={values.departure}
                        options={arrivals}
                        value={values.departure}
                        disabled
                        isDisabled
                        onChange={() => console.log(values.arrival)}
                      />
                    </SelectBoxWrapper>
                    <InputDataWrapper>
                      {!isMinScreen768 ? (
                        <>
                          <InputMobWrapper>
                            <InputField
                              type={"date"}
                              name="returnDate"
                              label="Дата"
                              bgImage={Svg.Calendar}
                              value={values.returnDate}
                              onChange={handleChange}
                            />
                            <SelectField
                              id="time"
                              name="returnTime"
                              label="Час відправлення"
                              placeholder="--:--"
                              options={timesReturnOptions}
                              onChange={(option) => {
                                setFieldValue("returnTime", (option as any).value)
                                setChooseReturnTime(() => (option as any).value)
                              }}
                            />
                          </InputMobWrapper>
                          <InputField
                            type={"number"}
                            name="passengersReturn"
                            label="Пасажири"
                            bgImage={Svg.ArrowDown}
                            value={values.passengersReturn}
                            onChange={(event) => {
                              const pass = event.target.value
                              setFieldValue("passengersReturn", pass);
                              setPassArrival(Number(`${pass}`))
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <InputField
                            type={"date"}
                            name="returnDate"
                            label="Дата"
                            bgImage={Svg.Calendar}
                            value={values.returnDate}
                            onChange={handleChange}
                            />
                          <SelectField
                            id="time"
                            name="returnTime"
                            label="Час відправлення"
                            placeholder="--:--"
                            options={timesReturnOptions}
                            onChange={(option) => {
                              setFieldValue("returnTime", (option as any).value)
                              setChooseReturnTime(() => (option as any).value)
                              }}
                          />
                          <InputField
                            type={"number"}
                            name="passengersReturn"
                            label="Пасажири"
                            bgImage={Svg.ArrowDown}
                            value={values.passengersReturn}
                            onChange={(event) => {
                              const pass = event.target.value
                              setFieldValue("passengersReturn", pass);
                              setPassArrival(Number(`${pass}`))
                            }}
                          />
                        </>
                      )}
                    </InputDataWrapper>
                  </ReturnWrapper>
                )}
                <ConfirmedWrapper>
                  <PriceWrapper>
                    <TextWrapper>Вартість поїздки:</TextWrapper>
                    <NumberWrapper>{priceTicket} грн</NumberWrapper>
                    {check && (
                      <StyledWrapperInclude>
                        <IconCheck src={Svg.Success} alt="icon check" />
                        <Styled.PBody2>Включно зі зворотньою</Styled.PBody2>
                      </StyledWrapperInclude>
                    )}
                  </PriceWrapper>
                  <ButtonWrapper>
                    <ButtonForm
                      type="submit"
                      disabled={isSubmitting}
                      title="Оформити квиток"
                      width={isMinScreen1440 ? "100%" : "240px"}
                      maxWidth="100%"
                      height={check && isMinScreen768 ? "87px" : "63px"} 
                    >
                      <img src={Svg.Arrow} alt="arrow" />
                    </ButtonForm>
                  </ButtonWrapper>
                </ConfirmedWrapper>
              </FormWrapper>
            )}
          </Formik>
        </StyledFormWrapper>
      </ContainerForm>
    </>
  );
};
