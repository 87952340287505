import { Svg } from "common/assets";
import { EColors } from "common/styles";
import { FONT } from "common/utils";
import styled from "styled-components";

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  gap:8px;
  @media screen and (max-width: 999px) {
    display: none;
  }

`;
export const SocialWrapper = styled.div`
  display: flex;
`;
export const SelectWrapper = styled.select`
  width: 178px;
  height: 24px;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 122%;
  border: none !important;
  background: transparent;
  box-sizing: border-box;
  margin: 0;
  outline: none !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  border: none !important;
  -moz-border: none !important;
  -webkit-border: none !important;
  appearance: none;
  background-image: url(${Svg.ArrowDown});
  background-repeat: no-repeat;
  background-position: right -4px top 50%, 0 0;
  background-size: 24px 24px;
  outline: none;
  cursor: pointer;
  margin-left: 8px;
  &:hover,
  &:focus,
  &:active {
    background: transparent;
    text-decoration: none;
    outline: none !important;
    border: none !important;
    -moz-border: none !important;
    -webkit-border: none !important;
    background-image: url(${Svg.ArrowDown});
    background-repeat: no-repeat;
    background-position: right -4px top 50%, 0 0;
    background-size: 24px 24px;
    cursor: pointer;
  }
  & option {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: ${EColors.primary_100};
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 122%;
    border: none;
    box-sizing: border-box;
    margin: 0;
    outline: none !important;
    border: none !important;
    -moz-border: none !important;
    -webkit-border: none !important;
    -moz-appearance: none;
    -webkit-appearance: none;
    border: none;
    appearance: none;
    cursor: pointer;
  }
  
`;

export const CallButton = styled.button`
width:100%;
height: 100%;
font-family: 'Roboto', sans-serif;
font-size: 18px; 
font-weight: 600;
line-height: 126%;
color: ${EColors.primary_400};
text-align: center; 
border:none;
outline:none;
background: transparent;
cursor: pointer;
@media screen and (min-width: 768px) {
  font-size: 16px; 
}
`