import { Svg } from "common/assets";
import { Styled } from "components/Styled";
import { FlexWrapper } from "components/Styled/Styled";
import { useEffect, useRef, useState } from "react";
import {
  IconImage,
  StyledAccordionItem,
  StyledInvisibleContent,
  StyledSpanContext,
  StyledTitle,
  StyledVisibleTitle,
} from "./styled";
import { AccordionData } from "./types";

export const AccordionItem = ({ data, isOpen, btnOnClick }: { data: AccordionData; isOpen:boolean, btnOnClick:()=>void }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (isOpen) {
      const contentEl = contentRef.current as HTMLDivElement;
      setHeight(contentEl.scrollHeight);
    } else {
      setHeight(0);
    }
  },[isOpen])
  
  return (
    <StyledAccordionItem >
      <FlexWrapper justify="flex-start">
        <StyledVisibleTitle
          onClick={btnOnClick}
          active={`${isOpen?'active':''}`}
        >
          <StyledTitle>{data.title}</StyledTitle>
          <IconImage
            src={isOpen ? `${Svg.ArrowUp}` : `${Svg.ArrowAccordion}`}
            active={`${isOpen ? 'active' : ''}`}
            alt="icon"
          />

        </StyledVisibleTitle>
      </FlexWrapper>
      <StyledInvisibleContent style={{height}}>
        <StyledSpanContext ref={contentRef}>
            {data.content}
          </StyledSpanContext>
      </StyledInvisibleContent>
    </StyledAccordionItem>
  );
};
