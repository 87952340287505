import { Png } from "common/assets";

export const GOOGLE_KEY = 'AIzaSyAKncTpO6ELHh0b83XwUqjp08D_QYq94QE';
export const items = [
    {
        route: "Варшава - Луцьк - Тернопіль",
        text: "Варшава - Груєц - Варка - Козеніце - Люблін -Тернопіль",
        src: "https://www.google.com/maps/embed?pb=!1m64!1m12!1m3!1d1908862.265643732!2d22.609758779534946!3d51.41580317893346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m49!3e0!4m5!1s0x471ecc669a869f01%3A0x72f0be2a88ead3fc!2z0JLQsNGA0YjQsNCy0LAsINCf0L7Qu9GM0YnQsA!3m2!1d52.2296756!2d21.012228699999998!4m5!1s0x471922999e7ef00b%3A0x504f231e08183a82!2z0JPRgNGD0ZTRhtGMLCDQn9C-0LvRjNGJ0LA!3m2!1d51.8655118!2d20.8666034!4m5!1s0x4718e7354377e2ed%3A0x943bb41d59f97a62!2z0JLQsNGA0LrQsCwg0J_QvtC70YzRidCw!3m2!1d51.7840019!2d21.1909879!4m5!1s0x4718922352c884fb%3A0xbe5b6eeb9f563c43!2z0JrQvtC30LXQvdGW0YbQtSwg0J_QvtC70YzRidCw!3m2!1d51.585532799999996!2d21.5511768!4m5!1s0x472257141e154061%3A0x5528ee7af6e8e95f!2z0JvRjtCx0LvRltC9LCDQn9C-0LvRjNGJ0LA!3m2!1d51.246453599999995!2d22.568446299999998!4m5!1s0x472f9b5c32f33d6f%3A0x895dbc6e319b4a6!2z0JTRg9Cx0L3Qviwg0KDRltCy0L3QtdC90YHRjNC60LAg0L7QsdC70LDRgdGC0Yw!3m2!1d50.4009139!2d25.7590674!4m5!1s0x472fc028f7a2deed%3A0x36212ef5c50e22e4!2z0JrRgNC10LzQtdC90LXRhtGMLCDQotC10YDQvdC-0L_RltC70YzRgdGM0LrQsCDQvtCx0LvQsNGB0YLRjA!3m2!1d50.1078135!2d25.7276661!4m5!1s0x473036ad4b82ce75%3A0xc484a447edb154e8!2z0KLQtdGA0L3QvtC_0ZbQu9GMLCDQotC10YDQvdC-0L_RltC70YzRgdGM0LrQsCDQvtCx0LvQsNGB0YLRjA!3m2!1d49.553517!2d25.594766999999997!5e0!3m2!1suk!2sua!4v1694007451456!5m2!1suk!2sua",
        srcImage: `${Png.RouteOne}`,
     
    },
    {
        route: "Варшава - Варка - Луцьк",
        text: "Варшава - Груєц - Варка - Козеніце - Люблін - Ковель - Луцьк ",
        src: "https://www.google.com/maps/embed?pb=!1m58!1m12!1m3!1d1541044.5329396625!2d22.74779655426489!3d51.95360730473207!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m43!3e0!4m5!1s0x471ecc669a869f01%3A0x72f0be2a88ead3fc!2z0JLQsNGA0YjQsNCy0LAsINCf0L7Qu9GM0YnQsA!3m2!1d52.2296756!2d21.012228699999998!4m5!1s0x471922999e7ef00b%3A0x504f231e08183a82!2z0JPRgNGD0ZTRhtGMLCDQn9C-0LvRjNGJ0LA!3m2!1d51.8655118!2d20.8666034!4m5!1s0x4718e7354377e2ed%3A0x943bb41d59f97a62!2z0JLQsNGA0LrQsCwg0J_QvtC70YzRidCw!3m2!1d51.7840019!2d21.1909879!4m5!1s0x4718922352c884fb%3A0xbe5b6eeb9f563c43!2z0JrQvtC30LXQvdGW0YbQtSwg0J_QvtC70YzRidCw!3m2!1d51.585532799999996!2d21.5511768!4m5!1s0x472257141e154061%3A0x5528ee7af6e8e95f!2z0JvRjtCx0LvRltC9LCDQn9C-0LvRjNGJ0LA!3m2!1d51.246453599999995!2d22.568446299999998!4m5!1s0x47243affb949afa3%3A0x1957580a9b3290b9!2z0JrQvtCy0LXQu9GMLCDQktC-0LvQuNC90YHRjNC60LAg0L7QsdC70LDRgdGC0Yw!3m2!1d51.209018!2d24.6980251!4m5!1s0x472599eba185965d%3A0xd25274a2228db86c!2z0JvRg9GG0YzQuiwg0JLQvtC70LjQvdGB0YzQutCwINC-0LHQu9Cw0YHRgtGM!3m2!1d50.747232999999994!2d25.325383!5e0!3m2!1suk!2sua!4v1694004537821!5m2!1suk!2sua",
        srcImage: `${Png.RouteTwo}`,
       
    },
   
]
 