import { Styled } from "components/Styled";
import styled from "styled-components";

export const Wrapper = styled.div``
export const StyledTitle = styled(Styled.H2)`
 opacity: 0;
  animation: appearence 0.5s linear 0.3s forwards;
  @keyframes appearence {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`