import { createGlobalStyle } from 'styled-components'
import { EColors } from './colors'

export const GlobalStyles = createGlobalStyle`

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box ;

}

body, html {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    height: 100%;
    background-color:${EColors.white};
    scroll-behavior:smooth;
}
`
