import { Svg } from "common/assets";
import { useResolution } from "common/hooks";
import { EColors } from "common/styles";
import { Modal } from "components/modal";
import { Styled } from "components/Styled";
import { useEffect, useState } from "react";
import { Component } from "./component";
import { ERROR_MES } from "./data";
import { ImageIcon, StyledWrapperStatus } from "./styled";
import { TModalProps } from "./types";


export const ModalCall = ({ modalActive, setModalActive }: TModalProps) => {
  const [message, setMessage] = useState("");
  const { isMinScreen455 } = useResolution();

  useEffect(() => {
    if (modalActive) {
      setMessage("");
    }
  }, [modalActive]);
  return (

    <Modal
      modalActive={modalActive}
      setModalActive={setModalActive}
      maxWidth={isMinScreen455 ? "527px" : "calc(100% - 32px)"}
    >
      {message !== "" ? (
        <StyledWrapperStatus>
          <ImageIcon
            src={message === ERROR_MES ? `${Svg.Error}` : `${Svg.Success}`}
            alt="icon"
          />
          <Styled.H3
            align="center"
            color={
              message === ERROR_MES ? EColors.neutral_600 : EColors.neutral_800
            }
          >
            {message}
          </Styled.H3>
        </StyledWrapperStatus>
      ) : (
        <Component.FormCall setMessage={setMessage} />
      )}
    </Modal>
  );
};
