import { EColors } from "common/styles";
import { Styled } from "components/Styled";
import styled from "styled-components";

export const StyledConditionPage = styled.div`
display:flex;
flex-direction: column;
justify-content:space-between;
background-color: ${EColors.primary_100};
width: 100%;
min-height: 100vh;
`
export const StyledLogo = styled.a`
display:inline-flex;
text-decoration:none;
width: 100%;
padding:8px 16px 20px;
  @media screen and (min-width: 768px) {
   padding:20px 24px; 
  }
   @media screen and (min-width: 1440px) {
   padding:20px 72px; 
  }
`
export const StyledPoliticsContent = styled.div`
  overflow-y: visible;
  padding:8px 16px;
  @media screen and (min-width: 768px) {
   padding:20px 24px; 
  }
   @media screen and (min-width: 1440px) {
   padding:20px 72px; 
  }
`;
export const StyledFooter = styled.footer`
width:100%;
background: ${EColors.neutral_800};
`
export const StyledFooterContainer = styled(Styled.Container)`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 34px 16px;
  
  @media screen and (max-width: 999px) {
    gap: 48px;
  }
  @media screen and (min-width: 1000px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 48px 24px;
  }
  @media screen and (min-width: 1440px) {
    padding: 48px 72px;
  }
`;