import { EColors, EFont } from 'common/styles'
import {
  TFlexConstructorArgs,
  TStyledConstructor,
  TFontConstructorArgs,
} from './types'

export const FLEX: TStyledConstructor<TFlexConstructorArgs> = ({
  direction = 'row',
  justify = 'center',
  align = 'center',
  wrap = 'wrap',
  gap = '0px',
}) => {
  return `
      display: flex;
      align-items: ${align};
      justify-content: ${justify};
      flex-direction: ${direction};
      flex-wrap: ${wrap};
      gap: ${gap};
    `
}

export const FONT: TStyledConstructor<TFontConstructorArgs> = ({
  color = EColors.neutral_800,
  family = EFont.Roboto,
  weight = '400',
  size = '18px',
  align = 'left',
}) => {
  return `
    text-align: ${align};
    font-family: ${family};
    font-weight: ${weight};
    font-size: ${size};
    color: ${color};
  `
}
