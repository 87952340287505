export const items = [
    {
        number: '01',
        title: 'Підготовка та бронювання',
        text:'Ознайомтеся з розкладом, забронюйте квиток та перевірте деталі. Підготуйте необхідні документи та особисті речі.'
    },
    {
        number: '02',
        title: 'Прибуття та посадка',
        text: "З'явіться на місце посадки заздалегідь, щоб пройти реєстрацію та процедури посадки."
    },
    {
        number: '03',
        title: 'Комфортна подорож',
        text: 'Відправляйтесь в дорогу, насолоджуючись комфортом та безпекою перевезень.'
    },
    {
        number: '04',
        title: 'Завершення та відгук',
        text: 'Прибули на місце призначення? Поділіться враженнями про подорож, допомагаючи нам поліпшити наші послуги.'
    },
    
]