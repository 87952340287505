import { Svg } from "common/assets";
import { ConditionContent } from "./ConditionContent";
import { Footer } from "./Footer";
import { StyledConditionPage, StyledLogo } from "./styled";

export const ConditionPayment = () => {
  return (
    <StyledConditionPage>
      <StyledLogo href="/">
        <img src={Svg.Logo} alt="logo" />
      </StyledLogo>
      <ConditionContent />
      <Footer />
    </StyledConditionPage>
  );
};
