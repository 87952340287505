import { Section } from "./section";

export const links = [
  {
    title: "Наші напрямки",
    href: `#${Section.routes}`,
  },

  {
    title: "Наші зупинки",
    href: `#${Section.stops}`,
  },
  {
    title: "Питання та відповіді",
    href: `#${Section.questions}`,
  },
  {
    title: "Контакти",
    href: `#${Section.contacts}`,
  },
]
