import { Svg } from 'common/assets'
import { EColors } from 'common/styles'
import { Styled } from 'components/Styled'
import { ImageIcon} from './styled'

export const Error = () => {
  const title = 'Оформлення не відбулось:' + '(';

  return (
    <>
        <ImageIcon src={Svg.Error} alt='icon' />
        <Styled.H3 align='center'>{title}</Styled.H3>
        <Styled.PBody1 color={EColors.neutral_600} align='center'>Під час оформлення квитка сталась помилка. Будь ласка, спробуйте ще раз! </Styled.PBody1>
    </>
  )
}
