import { Main } from "./Main";
import { About } from "./About";
import { Routes } from "./Routes";
import { Stops } from "./Stops";
import { FAQ } from "./FAQ";
import { StepsTravel } from "./StepsTravel";
import { LoadApp } from "./LoadApp";


export const Layout = { Main, About, Routes, Stops, FAQ, LoadApp, StepsTravel };
