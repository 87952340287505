import { EColors } from "common/styles";
import { Styled } from "components/Styled";
import styled from "styled-components";

export const FormWrapper = styled.div`
  width: 100%;
  margin-top: 12px;
  padding: 28px;
  background: ${EColors.neutral_100};
  box-shadow: 0px 12px 24px 0px rgba(166, 166, 166, 0.2);
`;
export const StyledRow = styled(Styled.FlexWrapper)`
  justify-content: space-between;
  gap: 23px;
  padding-bottom: 28px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 28px;
  }
`;
export const StyledRowBorder = styled(StyledRow)`
  margin-bottom: 28px;
  border-bottom: 1px solid ${EColors.neutral_300};
`;
export const StyledHalf = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;
export const StyledTitle = styled(Styled.Subtitle_2)`
  display: inline-block;
  margin-bottom: 16px;
`;

export const StyledPriceWrapper = styled.div`
  display: flex;
  padding: 16px;
  align-items: flex-end;
  gap: 24px;
  align-self: stretch;
  padding: 16px;
  background: ${EColors.neutral_200};
  @media screen and (max-width: 767px) {
    justify-content: center;
    align-items: center;
  }
`;

export const StyledPriceContainer = styled(Styled.FlexWrapper)`
  justify-content: space-between;
  gap: 40px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 4px;
  }
`;

