import styled from "styled-components";

export const SocialMediaButton = styled.a`
display: inline-flex;
width: 100%;
height: 100%;
 text-decoration:none;
  border: none;
  background-color: transparent;
  transition: all 0.3s linear;
  &:hover{
    transform: rotate(25deg);
  }
`