import { EColors } from "common/styles";
import { FLEX } from "common/utils";
import { Styled } from "components/Styled";
import styled from "styled-components";



export const FormWrapper = styled.form`
width: 100%;
padding:16px 16px 28px;
${FLEX({ direction: "column" })};
@media screen and (min-width: 768px) {
    padding:16px 36px 28px;
};
`
export const StyledTitle = styled(Styled.H1)`
margin-bottom:16px;
`
export const StyledSpanText = styled(Styled.PBody1)`
align-self: stretch; 
text-align: center; 
color: ${EColors.neutral_600};
margin-bottom:28px;
`
export const InputBoxWrapper = styled.div`
width: 100%;
display:flex;
flex-direction:column;
gap:28px;
margin-bottom:28px;
@media screen and (min-width: 768px) {
    margin-bottom:44px;
}
`
export const StyledWrapperStatus = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
gap:24px;
padding:16px 36px 76px;
`

export const ImageIcon = styled.img`
width: 127px;
height: 126px; 
`