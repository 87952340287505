import { EColors, EFont } from "common/styles";
import { CopyRight } from "components/CopyRight";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Link = styled(NavLink)`
  cursor: pointer;
  p {
    font-family: ${EFont.Roboto};
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    color: ${EColors.neutral_600};
    transition: all 0.3s linear;
  }
  text-decoration: none;

  &:hover p {
    color: ${EColors.primary_300};
  }
`;

export const PolicyPrivacyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

export const StyledCopyRight = styled(CopyRight)`
  align-self: center;
  @media screen and (max-width: 1000px) {
    display: flex;
  }
`;
export const StyledPoliticsContent = styled.div`
  padding: 20px 16px;
  overflow-y: visible;
   @media screen and (min-width: 1000px) {
    padding: 40px 40px; 
   }
`;
export const StyledPolicyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;
