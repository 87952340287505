import { useIntersection } from "common/hooks"
import { useRef } from "react"
import { CardItem } from "./CardItem"
import { items } from "./data"
import { StyledListCards } from "./styles"
import { TCardProps } from "./types"

export const ListCards = () => {
    const ref = useRef(null)
    const isInteract = useIntersection({ element: ref })
    return (
        <StyledListCards ref={ref}>
            {items.map((item: TCardProps, index: number) => (
                <CardItem key={index} item={item} />
            ))}
        </StyledListCards>
    )
}