import { EColors } from 'common/styles'
import { CSSProperties } from 'react'
import { StyledCopyRight } from './styled'


type CopyRightProps = {
  style?: CSSProperties
}

export const CopyRight = ({ ...props }: CopyRightProps) => {

  return (
    <>
      <StyledCopyRight {...props} color={EColors.neutral_600}>
        © AutoServiceTur, 2023 
      </StyledCopyRight>
    </>
  )
}
