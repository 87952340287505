import { Png, Svg } from "common/assets";
import { useIntersection, useResolution } from "common/hooks";
import { Styled } from "components/Styled";
import { useRef } from "react";
import { Fade } from "react-awesome-reveal";
import { LoadingButton } from "./LoadingButton";
import {
  ImageIphone,
  StyledButtonsWrapper,
  StyledSectionLoadApp,
  StyledTitle,
  StyledWrapperLoadApp,
} from "./styled";

export const LoadApp = () => {
  const refAnimation = useRef(null);
  const isInteract = useIntersection({ element: refAnimation });
  const { isMinScreen768 } = useResolution();

  return (
    <StyledSectionLoadApp>
      <StyledWrapperLoadApp ref={refAnimation}>
        <Styled.Container>
          <Fade
            triggerOnce
            direction="left"
            duration={500}
            delay={1000}
            style={{ opacity: isInteract ? 1 : 0 }}
          >
            <StyledTitle>Завантажуйте наш додаток</StyledTitle>
            <StyledButtonsWrapper>
              <LoadingButton
                href=""
                srcImage={isMinScreen768 ? `${Svg.Apple}` : `${Png.App}`}
              />
              <LoadingButton
                href=""
                srcImage={isMinScreen768 ? `${Svg.Google}` : `${Png.Google}`}
              />
            </StyledButtonsWrapper>
          </Fade>
          {!isMinScreen768 && (
            <Fade
              triggerOnce
              direction="left"
              duration={500}
              delay={400}
              style={{ opacity: isInteract ? 1 : 0 }}
            >
              <Styled.FlexWrapper>
                <ImageIphone src={Png.BgIphoneMob} alt="iphone" />
              </Styled.FlexWrapper>
            </Fade>
          )}
        </Styled.Container>
      </StyledWrapperLoadApp>
    </StyledSectionLoadApp>
  );
};
