import { Png } from "common/assets";
import { EColors } from "common/styles";
import { Button } from "components/buttons";
import { Styled } from "components/Styled";
import styled from "styled-components";

export const StyledMainSection = styled.section<{
  check?: boolean;
}>`
  width: 100%;
  min-height: calc(100vh - 84px);
  padding-top: 25.5px;
  padding-bottom: 49.5px;
  background: ${EColors.primary_100};
  @media screen and (min-width: 768px) {
    padding-top: 60.5px;
    min-height:calc(100vh - 108px);
    height:740px;
    background-image: url('${Png.BgHero}');
    background-image: -webkit-image-set(url('${Png.BgHero}') 1x,url('${Png.BgHeroBig}') 2x);
    background-size:auto;
    background-repeat: no-repeat;
    background-position:bottom right;
  }
`;

export const ContainerHero = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 16px;
  @media screen and (min-width: 768px) {
    padding: 0 24px;
  }
  @media screen and (min-width: 1440px) {
    padding: 0 72px;
  }
`;
export const StyledHeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 636px;
`;
export const ButtonIcon = styled.svg`
  width: 24px;
  height: 24px;
  fill: ${EColors.white};
  transition: all 0.3s linear;
  margin-right: 8px;
`;

export const ButtonHero = styled(Button)`
  margin-top: 32px;
  margin-bottom: 48px;

  @media screen and (min-width: 1000px) {
    margin-top: 36px;
    margin-bottom: 12px;
  }
`;

export const StyledTitle = styled(Styled.H1)`
  opacity: 0;
  animation: appearence 0.5s linear 0.3s forwards;
  @keyframes appearence {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
