import { Styled } from "components/Styled";
import { Link, PolicyPrivacyContainer, StyledCopyRight, StyledPolicyWrapper } from "./styled";

export const General = () => {
  return (
    <StyledPolicyWrapper>
      <PolicyPrivacyContainer>
        <Link to='/policy' target="_blank" rel="noopener noreferrer">
          <Styled.PBody2>Політика конфіденційності</Styled.PBody2>
        </Link>
        <Link to='/conditions' target="_blank" rel="noopener noreferrer">
          <Styled.PBody2>Договір оферти</Styled.PBody2>
        </Link>
        <Link to='/refunds' target="_blank" rel="noopener noreferrer">
          <Styled.PBody2>Умови повернення квитка</Styled.PBody2>
        </Link>
      </PolicyPrivacyContainer>
      <StyledCopyRight />
    </StyledPolicyWrapper>
  );
};
