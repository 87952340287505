import { Svg } from "common/assets";

export const items = [
    {
        title: "Популярні напрямки",
        describe: "Ми покладаємо великий акцент на безпеку пасажирів. Всі наші автобуси обладнані сучасними системами безпеки.",
        srcIcon: `${Svg.Travel}`,
    },
    {
        title: "Комфорт та зручність",
        describe: "Від просторих сидінь до безкоштовного Wi-Fi на борту – ми дбаємо про ваш комфорт протягом всієї подорожі.",
        srcIcon: `${Svg.Mobile}`,
    },
    {
        title: "Відмінне обслуговування",
        describe: "Наша команда завжди готова надати вам індивідуальну підтримку та консультації з будь-яких питань.",
        srcIcon: `${Svg.Love}`,
    },
]