import styled from "styled-components";
export const StyledPoliticsContent = styled.div`
  padding: 20px 16px;
  overflow-y: visible;
   @media screen and (min-width: 1000px) {
    padding: 40px 40px; 
   }
`;
export const StyledPolicyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;
