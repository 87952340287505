import { Config } from "common/config";
import { useResolution } from "common/hooks";
import { Component } from "./components";
import {
  StyledFooter,
  StyledFooterContainer,
  StyledFooterMenu,
} from "./styled";

export const Footer = () => {
  const { isMinScreen1000 } = useResolution();

  return (
    <StyledFooter id={Config.Section.contacts}>
      <StyledFooterContainer>
        <Component.BrandEntities />
        <StyledFooterMenu>
          <Component.Menu />
        </StyledFooterMenu>
        <Component.Contacts />
        {!isMinScreen1000 && <Component.General />}
      </StyledFooterContainer>
    </StyledFooter>
  );
};
