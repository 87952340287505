import { EColors } from "common/styles";
import styled from "styled-components";

export const StyledHeader = styled.header`
  width: 100%;
  padding:8px 16px;
  background-color: ${EColors.primary_100};
  z-index:10;
  @media screen and (min-width: 768px) {
   padding:20px 24px; 
  }
   @media screen and (min-width: 1440px) {
   padding:20px 72px; 
  }
`;

export const Logo = styled.img`
  width: 102px;
  height: 68px;
  opacity: 0;
  transform: translateX(-100%);
  animation: leftAppearence 0.5s ease-out  forwards;
  @keyframes leftAppearence  {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translateX(0%);
    }
  }
`;
