import React from 'react';
import { useField } from "formik";
import { SelectInput } from './Select';
import { SelectProps } from './types';

export const SelectField: React.FC<SelectProps> = ({
    id,
    name,
    label,
    placeholder,
    options = [],
    disabled,
    point,
    setPoint,
    ...props
}) => {
    const [field, meta, { setValue }] = useField(name);
    
 
   
    return <SelectInput
        {...field}
        name={name}
        placeholder={placeholder}
        label={label}
        options={options}
        disabled={disabled}
        /* eslint-disable */
      
        value={options.filter((option) =>
            option.value === field?.value ? option.value : '',
        )}
        {...props}
        isInvalid={Boolean(meta.error && meta.touched)}
        errorText={String(meta.error)}
    />;
}