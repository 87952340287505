import { EColors, EFont } from "common/styles";
import styled from "styled-components";
import { TFlexWrapper, TText, TTextProps } from "./types";

export const Section = styled.section<{
  color?: string;
}>`
  padding-bottom: 75px;
  background: ${(props) => props.color};
  @media screen and (min-width: 1000px) {
    padding-bottom: 128px;
  }
`;

export const Container = styled.div`
  width: 100%;
  padding: 0 16px;
  margin: 0 auto;
  @media screen and (min-width: 768px) {
    padding: 0 24px;
  }
  @media screen and (min-width: 1440px) {
    max-width: 1440px;
    padding: 0 72px;
  }
`;

export const FlexWrapper = styled.div<TFlexWrapper>`
  display: flex;
  width: ${({ width }) => width || "auto"};
  height: ${({ height }) => height || "auto"};
  flex-direction: ${({ direction }) => direction || "row"};
  align-items: ${({ align }) => align || "center"};
  justify-content: ${({ justify }) => justify || "center"};
  gap: ${({ gap }) => (gap ? `${gap}px` : "0px")};
  flex-wrap: ${({ wrap }) => wrap || "nowrap"};
  max-width: 100%;
`;

export const ContainerWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 48px;
  @media screen and (min-width: 768px) {
    flex-direction: row;
    gap: 24px;
  }
`;
const Text = (type: TText) => styled(type)<TTextProps>`
  text-align: ${({ align }) => align || "left"};
  color: ${({ color }) => color || EColors.neutral_800};
  font-family: ${EFont.Roboto}, sans-serif;
  font-weight: ${({ fontWeight }) => fontWeight || "400"};
  max-width: 100%;
  margin-bottom: ${({ mgBottom }) => mgBottom || "0"};
`;

export const H1 = styled(Text("h1"))`
  font-family: ${EFont.Oswald}, sans-serif;
  font-weight: 500;
  font-size: 36px;
  margin-bottom: 28px;
  @media screen and (max-width: 767px) {
    font-size: 22px;
    margin-bottom: 20px;
  }
`;

export const H2 = styled(Text("h2"))`
  font-family: ${EFont.Oswald}, sans-serif;
  font-weight: 500;
  font-size: 28x;
  margin-bottom: 24px;
  @media screen and (min-width: 768px) {
    font-size: 48px;
    line-height: 112%;
    letter-spacing: 0.35px;
    margin-bottom: 48px;
  }
`;
export const H3 = styled(Text("h3"))`
  font-family: ${EFont.Oswald}, sans-serif;
  font-weight: 500;
  font-size: 36px;
  @media screen and (max-width: 767px) {
    font-size: 22px;
  }
`;
export const PBody1 = styled(Text("p"))`
  font-size: 18px;
  line-height: 140%;
  font-feature-settings: "case" on;
`;

export const Subtitle_1 = styled(Text("span"))`
  font-family: ${EFont.Roboto}, sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 140%;
  font-feature-settings: "case" on;
`;

export const Subtitle_2 = styled(Subtitle_1)`
  font-size: 20px;
  line-height: 122%;
`;

export const TextPhone = styled.span<{
  color?: string;
}>`
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 140%;
  color: ${({ color }) => (color ? `${color}` : EColors.neutral_800)};
`;
export const PBody1SB = styled(PBody1)`
  font-weight: 600;
`;

export const PBody2 = styled(Text("p"))`
  font-weight: 400;
  font-size: 16px;
  line-height: 126%;
`;
export const PBody2SB = styled(Text("p"))`
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 126%;
`;

export const ButtonText = styled(Text("span"))`
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  font-feature-settings: "case" on;
`;

export const Half = styled.div`
  width: 100%;
  @media screen and (min-width: 768px) {
    width: 50%;
  }
`;

export const H4 = styled(Text("h4"))`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
`;
