import { useIntersection } from "common/hooks";
import { useRef, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { AccordionItem } from "./AccordionItem";

import { AccordionData } from "./types";

export const Accordion = ({ items }: { items: AccordionData[] }) => {
  const [currentIdx, setCurrentIdx] = useState(-1);
  const ref = useRef(null)
  const isInteract = useIntersection({ element: ref })

  const btnOnClick = (index: number) => {
    setCurrentIdx((currentValue) => (currentValue !== index ? index : -1));
  };
  return (
     <Fade
      cascade
      triggerOnce
      direction="up"
      delay={500}
      duration={500}
      damping={0.5}
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "28px",
        opacity: isInteract ? "1" : "0",
      }}
    >
      {items.map((item, index) => (
        <AccordionItem
          data={item}
          key={index}
          isOpen={index === currentIdx}
          btnOnClick={() => btnOnClick(index)}
        />
      ))}
    </Fade>
  );
};
