import { Config } from 'common/config'
import { EColors } from 'common/styles'
import { Styled } from 'components/Styled'
import { links } from './data'
import { ItemsList, Link, LinkItem } from './styled'


export const Menu = () => {
  
  return (
    <>
        <Styled.PBody1SB color={EColors.neutral_600}>Меню</Styled.PBody1SB>
        {/* Menu list */}
        <ItemsList>
          {links.map((link, index) => (
            <LinkItem key={index}>
              <Link href={link.href} >{link.title}</Link>
            </LinkItem >
          ))}
        </ItemsList>
      
    </>
  )
}
